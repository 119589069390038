var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DetailsCommentsView" },
    [
      _c("CommentsList", {
        staticClass: "mb-4",
        attrs: {
          comments: _vm.commentsData,
          "is-loading": _vm.commentsStatus.LOADING,
          "entity-uuid": _vm.vehicleUuid,
          callback: _vm.getCommentsByUuid,
          entity: "vehicle",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }