export default {
  watch: {
    payload: {
      handler() {
        this.$emit('update', this.payload);
      },
      deep: true,
    },
  },
};
