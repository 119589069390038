export const CHASSIS_VALIDATION = {
  isRequired: true,
  isAlphanumeric: {
    message: 'Only letters and numbers allowed',
  },
  isMinLength: {
    length: 17,
    message: 'Must have 17 characters',
  },
  isPattern: {
    pattern: /^[^IOQ]*$/,
    message: 'Letters I, O, Q are not allowed',
  },
};
