<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex';
import { MuiAlgoliaSelect } from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { ContentCellComponent } from '@/components';
import editMixin from './editMixin';

export default {
  name: 'EditHardwareComponent',
  components: {
    ContentCellComponent,
    MuiAlgoliaSelect,
  },
  mixins: [editMixin],
  props: {
    vehicleId: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isNonConnected: {
      type: Boolean,
      required: true,
    },
    hardware: {
      type: Object,
      default: () => ({}),
    },
    allowSecondaryFuelCard: {
      type: Boolean,
      default: false,
    },
    isConnectedCarPlatform: {
      type: Boolean,
      required: true,
    },
    cloudBoxxDeviceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      originalPayload: null,
      payload: {
        is_connected_car_platform: this.isConnectedCarPlatform,
        fuel_card_uuid: this.hardware.fuel_card_uuid,
        secondary_fuel_card_uuid: this.hardware.secondary_fuel_card_uuid,
        device_uuid: this.hardware.device_uuid,
      },
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['getOperatorFilter']),
    isIBoxxActive() {
      return this.isActive && !this.payload.is_connected_car_platform && !this.isNonConnected;
    },
    hasSameValues() {
      return isEqual(this.payload, this.originalPayload);
    },
  },
  watch: {
    hasSameValues(value) {
      this.$emit('update:payload', value);
    },
    // eslint-disable-next-line func-names
    'payload.is_connected_car_platform': function (value) {
      if (value) {
        this.payload.device_uuid = null;
      } else {
        this.payload.device_uuid = this.originalPayload.device_uuid;
      }
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.originalPayload = cloneDeep(this.payload);
  },
};
</script>

<template>
  <div class="EditHardwareComponent">
    <div class="mb-4">
      <label class="d-block emobg-font-weight-semibold mb-1">
        Device Type
      </label>
      <ui-radio
        :value="payload.is_connected_car_platform"
        :option="true"
        caption="Connected Car Platform device"
        name="is_connected_car_platform"
        data-test-id="connected_car-radio"
        @changevalue="() => payload.is_connected_car_platform = true"
      />
      <br>
      <ui-radio
        v-model="payload.is_connected_car_platform"
        :value="false"
        caption="iBoxx"
        name="iBoxx"
        data-test-id="iboxx-radio"
        @changevalue="() => payload.is_connected_car_platform = false"
      />
    </div>

    <div
      v-if="payload.is_connected_car_platform"
      class="mb-4"
    >
      <ContentCellComponent
        :value="cloudBoxxDeviceId"
        label="Device number"
      />
    </div>

    <div
      v-if="!payload.is_connected_car_platform"
      class="mb-4"
    >
      <MuiAlgoliaSelect
        v-model="payload.device_uuid"
        :filters="`${getOperatorFilter()} AND (vehicle_id: 'null' OR vehicle_id: ${vehicleId})`"
        :index="ALGOLIA_INDEXES.devices"
        :title="result => result.label_number"
        :show-clear="!isActive"
        :label="`Device number${isIBoxxActive ? '*': ''}`"
        :on-clear="() => null"
        name="device_uuid"
        path-value="uuid"
        placeholder="Select"
        class="w-100"
        data-test-id="device-select"
      >
        <span
          slot="clear"
          class="emobg-font-weight-semibold"
        >
          Unlink device
        </span>
      </MuiAlgoliaSelect>
    </div>

    <div
      :class="{
        'mb-4': allowSecondaryFuelCard,
      }"
    >
      <MuiAlgoliaSelect
        v-model="payload.fuel_card_uuid"
        :filters="`vehicle_id: 'null' OR vehicle_id: ${vehicleId} AND type: 'primary'`"
        :index="ALGOLIA_INDEXES.fuelcards"
        :title="result => `${result.company} - ${result.number}`"
        path-value="uuid"
        label="Fuel card"
        name="fuel_card_uuid"
        placeholder="Select"
        class="w-100"
        data-test-id="fuelcard-select"
      />
    </div>

    <div v-if="allowSecondaryFuelCard">
      <MuiAlgoliaSelect
        v-model="payload.secondary_fuel_card_uuid"
        :filters="`vehicle_id: 'null' OR vehicle_id: ${vehicleId} AND type: 'secondary'`"
        :index="ALGOLIA_INDEXES.fuelcards"
        :title="result => `${result.company} - ${result.number}`"
        label="Secondary fuel card"
        name="secondary_fuel_card_uuid"
        path-value="uuid"
        placeholder="Select"
        class="w-100"
        data-test-id="secondary_fuelcard-select"
      />
    </div>
  </div>
</template>
