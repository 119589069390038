var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "VehicleLocationRequestModal",
        attrs: {
          header: { isClosable: true },
          size: _vm.SIZES.small,
          buttons: _vm.modalButtons,
          title: "Request vehicle location",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain":
                      _vm.DOMAINS_MODEL.carsharing.vehicle.details.information,
                    scope: _vm.VEHICLE_DETAILS_SCOPES.position,
                    action: "Show vehicle location",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  {
                    attrs: {
                      id: "vehicleLocationRequestForm",
                      "extra-conditions": [_vm.isReasonSelected],
                    },
                    on: {
                      areAllValid: (valid) => (_vm.isFormValid = valid),
                      submit: _vm.onSubmit,
                    },
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "d-flex align-items-center mb-4" },
                      [
                        _c("span", [_vm._v("Provide reason")]),
                        _c(
                          "ui-tooltip",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              tooltip:
                                "Select one of the reasons below to see the vehicle location.",
                            },
                          },
                          [
                            _c("ui-icon", {
                              staticClass:
                                "emobg-color-ink-light emobg-color-ink-hover",
                              attrs: {
                                size: _vm.ICONS_SIZES.small,
                                icon: _vm.ICONS.infoFull,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.reasons, function (reason, index) {
                      return _c("ui-radio", {
                        key: reason.uuid,
                        staticClass: "d-block mb-2",
                        attrs: {
                          value: _vm.inputs.reasonUuid,
                          "data-test-id": `vehicle_location_modal-reason_${index}`,
                          option: reason.uuid,
                          caption: reason.name,
                          name: "reason",
                        },
                        on: {
                          changevalue: () =>
                            (_vm.inputs.reasonUuid = reason.uuid),
                        },
                      })
                    }),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }