var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "EditStatusComponent row" },
    [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("ui-toggle", {
            attrs: {
              value: _vm.payload.active,
              disabled: _vm.isIboxxWithoutDevice,
              text: `${_vm.payload.active ? "Active" : "Inactive"}`,
              name: "isActive",
              label: "Vehicle activation status",
            },
            on: { changevalue: (event) => (_vm.payload.active = event.detail) },
          }),
        ],
        1
      ),
      !_vm.isNonConnected
        ? _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("ui-toggle", {
                attrs: {
                  value: _vm.payload.api,
                  text: `${_vm.payload.api ? "Visible" : "Invisible"}`,
                  name: "isVisible",
                  label: "Vehicle visibility status",
                },
                on: {
                  changevalue: (event) => (_vm.payload.api = event.detail),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isIboxxWithoutDevice
        ? _c(
            "ui-alert",
            {
              staticClass: "col-6 mt-3 d-block",
              attrs: { color: _vm.COLORS.warning, icon: _vm.ICONS.alertFull },
            },
            [
              _vm._v(" Select "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("Device number"),
              ]),
              _vm._v(" in hardware section to make the vehicle "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("Active"),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }