var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DetailsSetupView" },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Vehicle setup ")]),
      _vm.information
        ? _c(
            "ui-card",
            {
              staticClass: "w-100 d-block",
              attrs: { header: "Vehicle information" },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(
                      _vm.vehicleInformationCells,
                      function (cell, cellIndex) {
                        return _c(
                          "div",
                          { key: cellIndex, staticClass: "col-4 pb-4" },
                          [
                            _c(
                              "p",
                              { staticClass: "mb-2 emobg-font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      cell.label || _vm.sentenceCase(cell.value)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "emobg-color-ink ellipsis text-capitalize",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.transformContentCell(cell)) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-3 d-flex justify-content-end" },
                  [
                    _c("EditButton", {
                      on: {
                        click: function ($event) {
                          return _vm.editVehicle(_vm.EDIT_SECTIONS.information)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "ui-card",
        { staticClass: "w-100 mt-4 d-block", attrs: { header: "Equipment" } },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-9" }, [
              _vm.checkedItems && _vm.checkedItems.length
                ? _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.checkedItems, function (item) {
                      return _c(
                        "div",
                        { key: item.equipment_uuid, staticClass: "col-4 my-2" },
                        [
                          _c("div", {
                            class: [
                              "d-inline-block",
                              `icons-${_vm.snakeCase(item.internal_name)}`,
                            ],
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.sentenceCase(item.name)) + " "
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _c("span", [_vm._v(_vm._s(_vm.FALLBACK_MESSAGE.dash))]),
            ]),
            _c(
              "div",
              { staticClass: "col-md-3 d-flex justify-content-end" },
              [
                _c("EditButton", {
                  on: {
                    click: function ($event) {
                      return _vm.editVehicle(_vm.EDIT_SECTIONS.equipment)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "ui-card",
        { staticClass: "w-100 mt-4 d-block", attrs: { header: "Description" } },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-9" },
              [
                _c("MarkdownComponent", {
                  attrs: { content: _vm.descriptionsData, language: "enGb" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3 d-flex justify-content-end" },
              [
                _c("EditButton", {
                  on: {
                    click: function ($event) {
                      return _vm.editVehicle(_vm.EDIT_SECTIONS.description)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.isDedicatedFleet
        ? _c(
            "ui-card",
            {
              staticClass: "w-100 mt-4 d-block position-relative hydrated",
              attrs: { header: "Corporate invoice sales order" },
            },
            [
              _c("ui-loader", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.salesOrderLoading,
                    expression: "salesOrderLoading",
                  },
                ],
                attrs: { absolute: "" },
              }),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("ContentCellComponent", {
                    staticClass: "col-md-3 mb-3",
                    attrs: {
                      label: "CS operator",
                      value:
                        _vm.get(
                          _vm.salesOrder,
                          "currentSalesOrder.companyName"
                        ) || _vm.FALLBACK_MESSAGE.dash,
                    },
                  }),
                  _c(
                    "ContentCellComponent",
                    {
                      staticClass: "col-md-3 mb-3",
                      attrs: { label: "Juridic status" },
                    },
                    [
                      _vm.get(_vm.salesOrder, "status")
                        ? _c(
                            "ui-badge",
                            {
                              attrs: {
                                color: _vm.getJuridicStatusColor(
                                  _vm.salesOrder.status
                                ),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.sentenceCase(_vm.salesOrder.status)
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : [
                            _vm._v(
                              " " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "
                            ),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "ContentCellComponent",
                    {
                      staticClass: "col-md-3 mb-3",
                      attrs: { label: "Sales order #" },
                    },
                    [
                      _vm.get(_vm.salesOrder, "currentSalesOrder.companyUuid")
                        ? _c(
                            "RouterLink",
                            {
                              staticClass: "emobg-link-primary emobg-body-2",
                              attrs: {
                                to: {
                                  name: _vm.crm.companies.detail
                                    .corporateContract.salesOrders,
                                  params: {
                                    companyUuid: _vm.get(
                                      _vm.salesOrder,
                                      "currentSalesOrder.companyUuid"
                                    ),
                                    contractUuid: _vm.get(
                                      _vm.salesOrder,
                                      "currentSalesOrder.contractUuid"
                                    ),
                                    salesOrderUuid: _vm.get(
                                      _vm.salesOrder,
                                      "currentSalesOrder.salesOrderUuid"
                                    ),
                                  },
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .get(
                                        _vm.salesOrder,
                                        "currentSalesOrder.salesOrderUuid",
                                        ""
                                      )
                                      .substr(0, 7)
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : [
                            _vm._v(
                              " " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "ContentCellComponent",
                    {
                      staticClass: "col-md-3 mb-3",
                      attrs: { label: "Start date" },
                    },
                    [_vm._v(" " + _vm._s(_vm.salesOrderStartDate) + " ")]
                  ),
                  _c(
                    "ContentCellComponent",
                    {
                      staticClass: "col-md-3 mb-3",
                      attrs: { label: "End date" },
                    },
                    [_vm._v(" " + _vm._s(_vm.salesOrderEndDate) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isNonConnected
        ? _c(
            "MuiCard",
            { staticClass: "w-100 mt-4 d-block" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center emobg-border-bottom-2 emobg-border-color-ground-light pb-3 mb-3",
                },
                [
                  _c(
                    "h3",
                    { staticClass: "flex-fill emobg-font-weight-semibold" },
                    [_vm._v(" Vehicle allocation ")]
                  ),
                  _c(
                    "ui-button",
                    {
                      on: {
                        clickbutton: function ($event) {
                          _vm.openVehicleAllocationForm(null, {
                            vehicle_uuid: _vm.vehicleUuid,
                            start: _vm.moment().add(1, "hour"),
                            end: _vm.moment("2050-12-31 00:00:00"),
                          })
                        },
                      },
                    },
                    [_vm._v(" Create new allocation ")]
                  ),
                ],
                1
              ),
              _c("MuiAlgoliaList", {
                ref: "allocationsList",
                attrs: {
                  filters: `vehicle_uuid: ${_vm.vehicleUuid}`,
                  index: _vm.ALGOLIA_INDEXES.vehicleLocation,
                  "item-actions": _vm.allocationItemsActions,
                  "query-parameters": { hitsPerPage: 5 },
                  "table-config": _vm.contentCells,
                  "no-cache": "",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "ui-card",
        { staticClass: "w-100 mt-4 d-block", attrs: { header: "Status" } },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-9" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("div", { staticClass: "emobg-font-weight-bold pb-3" }, [
                      _vm._v(" Vehicle activation status "),
                    ]),
                    _vm.isBoolean(_vm.isActive)
                      ? _c(
                          "ui-badge",
                          {
                            attrs: {
                              color: _vm.isActive
                                ? _vm.COLORS.success
                                : _vm.COLORS.danger,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.sentenceCase(_vm.activityLabel)) +
                                " "
                            ),
                          ]
                        )
                      : _c(
                          "ui-badge",
                          { attrs: { color: _vm.GRAYSCALE.ground } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.FALLBACK_MESSAGE.noData) + " "
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                !_vm.isNonConnected
                  ? _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c(
                          "div",
                          { staticClass: "emobg-font-weight-bold pb-3" },
                          [_vm._v(" Vehicle visibility status ")]
                        ),
                        _vm.isBoolean(_vm.isVisible)
                          ? _c(
                              "ui-badge",
                              {
                                attrs: {
                                  color: _vm.isVisible
                                    ? _vm.COLORS.success
                                    : _vm.COLORS.danger,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.sentenceCase(_vm.visibilityLabel)
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "ui-badge",
                              { attrs: { color: _vm.GRAYSCALE.ground } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.FALLBACK_MESSAGE.noData) +
                                    " "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-md-3 d-flex justify-content-end" },
              [
                _c("EditButton", {
                  on: {
                    click: function ($event) {
                      return _vm.editVehicle(_vm.EDIT_SECTIONS.status)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.hardware && !_vm.isNonConnected
        ? _c(
            "ui-card",
            {
              staticClass: "w-100 mt-4 d-block",
              attrs: { header: "Hardware" },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _c("div", { staticClass: "row pb-5" }, [
                    _c("h3", { staticClass: "col-12 pb-3" }, [
                      _vm._v(" Device "),
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c(
                        "div",
                        { staticClass: "emobg-font-weight-bold mb-2" },
                        [_vm._v(" Device type ")]
                      ),
                      _c("div", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.isConnectedCarPlatform
                              ? "Connected Car platform device"
                              : "iBoxx"
                          ),
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c(
                          "div",
                          { staticClass: "emobg-font-weight-bold mb-2" },
                          [_vm._v(" Device number ")]
                        ),
                        _vm.isConnectedCarPlatform && _vm.cloudBoxxDeviceId
                          ? _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.cloudBoxxDeviceId),
                              },
                            })
                          : !_vm.isConnectedCarPlatform &&
                            _vm.hardware.device_id
                          ? _c(
                              "RouterLink",
                              {
                                staticClass: "emobg-link-primary emobg-body-2",
                                attrs: {
                                  to: {
                                    name: _vm.fleetRouter.hardware.devices,
                                    query: {
                                      query: _vm.hardware.device_number,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.hardware.device_number) + " "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(_vm._s(_vm.FALLBACK_MESSAGE.dash)),
                            ]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row pb-5" }, [
                    _c("h3", { staticClass: "col-12 pb-3" }, [
                      _vm._v(" Fuel card "),
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c(
                        "div",
                        { staticClass: "emobg-font-weight-bold mb-2" },
                        [_vm._v(" Fuel card ")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.hardware.fuel_card_number ||
                              _vm.FALLBACK_MESSAGE.dash
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c(
                        "div",
                        { staticClass: "emobg-font-weight-bold mb-2" },
                        [_vm._v(" Fuel card PIN ")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.hardware.fuel_card_pin ||
                              _vm.FALLBACK_MESSAGE.dash
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c(
                        "div",
                        { staticClass: "emobg-font-weight-bold mb-2" },
                        [_vm._v(" Expiry date ")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.expiryDate(
                              _vm.hardware.fuel_card_expiry_date
                            ) || _vm.FALLBACK_MESSAGE.dash
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _vm.allowSecondaryFuelCard
                    ? _c("div", { staticClass: "row pb-5" }, [
                        _c("h3", { staticClass: "col-12 pb-3" }, [
                          _vm._v(" Secondary fuel card "),
                        ]),
                        _c("div", { staticClass: "col-4" }, [
                          _c(
                            "div",
                            { staticClass: "emobg-font-weight-bold mb-2" },
                            [_vm._v(" Secondary fuel card ")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.hardware.secondary_fuel_card_number ||
                                  _vm.FALLBACK_MESSAGE.dash
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "col-4" }, [
                          _c(
                            "div",
                            { staticClass: "emobg-font-weight-bold mb-2" },
                            [_vm._v(" Secondary fuel card PIN ")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.hardware.secondary_fuel_card_pin ||
                                  _vm.FALLBACK_MESSAGE.dash
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "col-4" }, [
                          _c(
                            "div",
                            { staticClass: "emobg-font-weight-bold mb-2" },
                            [_vm._v(" Expiry date ")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.expiryDate(
                                  _vm.hardware.secondary_fuel_card_expiry_date
                                ) || _vm.FALLBACK_MESSAGE.dash
                              ) +
                              " "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-3 d-flex justify-content-end" },
                  [
                    _c("EditButton", {
                      on: {
                        click: function ($event) {
                          return _vm.editVehicle(_vm.EDIT_SECTIONS.hardware)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.financialData
        ? _c(
            "ui-card",
            {
              staticClass: "w-100 mt-4 d-block",
              attrs: { header: "Financial information" },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-9" },
                  _vm._l(_vm.financialData, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "row" },
                      _vm._l(item, function (mode, label) {
                        return _c(
                          "div",
                          { key: label, staticClass: "col-4 pb-4" },
                          [
                            _c(
                              "div",
                              { staticClass: "mb-2 emobg-font-weight-bold" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.sentenceCase(label)) + " "
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              { staticClass: "color-ink" },
                              [
                                _vm.isString(mode) &&
                                _vm.includes(label, "_uuid")
                                  ? [_vm._v(" " + _vm._s(mode) + " ")]
                                  : _vm.isString(mode)
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.sentenceCase(mode)) +
                                          " "
                                      ),
                                    ]
                                  : _vm.isBoolean(mode)
                                  ? [
                                      _vm._v(
                                        " " + _vm._s(mode ? "Yes" : "No") + " "
                                      ),
                                    ]
                                  : _vm.isNumber(mode) &&
                                    _vm.includes(label, "_date")
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatUtc(
                                              mode,
                                              _vm.DATE_FORMAT.dob
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : _vm.includes(_vm.centsKeys, label) && mode
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.fromCentsToDecimals(mode, true)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : _vm.isNumber(mode) &&
                                    !_vm.includes(label, "_date")
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.formatThousands(mode)) +
                                          " "
                                      ),
                                    ]
                                  : [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            mode || _vm.FALLBACK_MESSAGE.dash
                                          ) +
                                          " "
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3 d-flex justify-content-end" },
                  [
                    _c("EditButton", {
                      on: {
                        click: function ($event) {
                          return _vm.editVehicle(_vm.EDIT_SECTIONS.financial)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "ui-form",
        { on: { submitform: _vm.confirmAction } },
        [
          _vm.isModalVisible
            ? _c(
                "GenericModalComponent",
                _vm._g(
                  {
                    staticClass: "VehicleFormComponent",
                    attrs: {
                      title: _vm.modaTitle,
                      header: { isClosable: true },
                    },
                    on: {
                      "close-modal": _vm.closeModal,
                      "modal-closed": _vm.closeModal,
                    },
                  },
                  _vm.$listeners
                ),
                [
                  _c(
                    "template",
                    { slot: "alerts" },
                    [
                      _c("StoreNotificationComponent", {
                        attrs: {
                          "store-domain":
                            _vm.DOMAINS_MODEL.carsharing.vehicle.details.setup,
                          scope: _vm.scopes.descriptions,
                          "custom-exceptions": [
                            _vm.carsharingErrors.chassisAlreadyExists,
                          ],
                          action: "edit vehicle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "body" },
                    [
                      _c(
                        "MuiValidationWrapper",
                        {
                          attrs: { id: "EditVehicleForm" },
                          on: {
                            areAllValid: (isValid) =>
                              (_vm.isFormValid = isValid),
                          },
                        },
                        [
                          _vm.editSection === _vm.EDIT_SECTIONS.status
                            ? _c("EditStatusComponent", {
                                attrs: {
                                  status: _vm.status,
                                  "is-non-connected": _vm.isNonConnected,
                                  "is-iboxx-without-device":
                                    _vm.isIboxxWithoutDevice,
                                },
                                on: {
                                  update: function ($event) {
                                    _vm.apiPayload = $event
                                  },
                                  "update:payload": (value) =>
                                    (_vm.hasSameValues = value),
                                },
                              })
                            : _vm._e(),
                          _vm.editSection === _vm.EDIT_SECTIONS.description
                            ? _c("EditDescriptionComponent", {
                                attrs: { descriptions: _vm.descriptionsData },
                                on: {
                                  update: function ($event) {
                                    _vm.apiPayload = $event
                                  },
                                  "update:payload": (value) =>
                                    (_vm.hasSameValues = value),
                                },
                              })
                            : _vm._e(),
                          _vm.editSection === _vm.EDIT_SECTIONS.equipment
                            ? _c("EditEquipmentComponent", {
                                attrs: { equipment: _vm.equipmentData },
                                on: {
                                  update: function ($event) {
                                    _vm.apiPayload = $event
                                  },
                                  "update:payload": (value) =>
                                    (_vm.hasSameValues = value),
                                },
                              })
                            : _vm._e(),
                          _vm.editSection === _vm.EDIT_SECTIONS.financial
                            ? _c("EditFinancialInfoComponent", {
                                attrs: {
                                  financial: _vm.head(_vm.financialData),
                                },
                                on: {
                                  update: function ($event) {
                                    _vm.apiPayload = $event
                                  },
                                  "update:payload": (value) =>
                                    (_vm.hasSameValues = value),
                                },
                              })
                            : _vm._e(),
                          _vm.editSection === _vm.EDIT_SECTIONS.information
                            ? _c("EditGeneralInfoComponent", {
                                attrs: { info: _vm.information },
                                on: {
                                  update: function ($event) {
                                    _vm.apiPayload = $event
                                  },
                                  "update:payload": (value) =>
                                    (_vm.hasSameValues = value),
                                },
                              })
                            : _vm._e(),
                          _vm.editSection === _vm.EDIT_SECTIONS.hardware
                            ? _c("EditHardwareComponent", {
                                attrs: {
                                  hardware: _vm.hardware,
                                  "vehicle-id": _vm.information.id,
                                  "allow-secondary-fuel-card":
                                    _vm.allowSecondaryFuelCard,
                                  "is-active": _vm.isActive,
                                  "is-non-connected": _vm.isNonConnected,
                                  "is-connected-car-platform":
                                    _vm.isConnectedCarPlatform,
                                  "cloud-boxx-device-id": _vm.cloudBoxxDeviceId
                                    ? _vm.cloudBoxxDeviceId
                                    : "",
                                },
                                on: {
                                  update: function ($event) {
                                    _vm.apiPayload = $event
                                  },
                                  "update:payload": (value) =>
                                    (_vm.hasSameValues = value),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center justify-content-sm-end align-items-center",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c("CancelButton", {
                        attrs: { "data-test-id": "cancel-button" },
                        on: { click: _vm.closeModal },
                      }),
                      _c(
                        "ui-button",
                        {
                          staticClass: "wmin-initial",
                          attrs: {
                            disabled: !_vm.isValidatedForm || _vm.hasSameValues,
                            loading: _vm.isModalLoading,
                            type: _vm.BUTTON_TYPES.submit,
                            "data-test-id": "edit-button",
                            form: "EditVehicleForm",
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isVehicleAllocationFormVisible
        ? _c("VehicleAllocationFormComponent", {
            attrs: {
              "vehicle-allocation": _vm.vehicleAllocation,
              initial: _vm.initialVehicleAllocationData,
              callback: _vm.refreshAllocationIndex,
              "close-modal": _vm.closeVehicleAllocationModal,
              "hide-vehicle": "",
            },
          })
        : _vm._e(),
      _vm.isRemoveModalVisible
        ? _c("RemoveVehicleAllocationFormComponent", {
            attrs: {
              header: null,
              "vehicle-allocation": _vm.vehicleAllocation,
              callback: _vm.refreshAllocationIndex,
              "close-modal": _vm.closeVehicleAllocationModal,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }