import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatUtc,
} from '@emobg/web-utils';

export function FUEL_SCHEMA({ isElectric = false, timezone }) {
  return [
    {
      header: 'First occurrence',
      template: ({ firstOccurrence }) => (
        firstOccurrence ? formatUtc(firstOccurrence, DATE_FORMAT.defaultExtended, timezone) : FALLBACK_MESSAGE.dash
      ),
    },
    {
      header: 'Last occurrence',
      template: ({ lastOccurrence }) => (
        lastOccurrence ? formatUtc(lastOccurrence, DATE_FORMAT.defaultExtended, timezone) : FALLBACK_MESSAGE.dash
      ),
    },
    {
      header: isElectric ? 'Traction battery level' : 'Fuel level',
      template: ({ value }) => value ? `${value}%` : FALLBACK_MESSAGE.noData,
    },
  ];
}
