<script>
import filter from 'lodash/filter';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { DELAY, getValue } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import fleet from '@domains/Carsharing/router/FleetRouterMap';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils';
import { DAMAGE_TYPE } from '../const/damages';
import DamagesComponent from './components/DamagesComponent';
import DeleteDamageModal from './components/DeleteDamageModal';
import { contentCells } from './config/damagesContentCells';
import PreviewDamageImageModal from '../../VehicleDamages/components/PreviewDamageImageModal';
import { isSystemUser } from '../../VehicleDamages/damages.const';

export default {
  name: 'DetailsDamagesView',
  components: {
    DamagesComponent,
    MuiAlgoliaList,
    PreviewDamageImageModal,
    DeleteDamageModal,
  },
  props: {
    refreshList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vehicleUuid: getValue(this, '$route.params.vehicle_uuid', null),
      imageToPreview: null,
      isPreviewModalOpen: false,
      isDeleteModalVisible: false,
      damageToDelete: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      generalData: state => state.general.data,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.damages, {
      damages: state => filter(state.data.damages, { status: DAMAGE_TYPE.notRepaired }),
      sides: state => state.data.images,
    }),
    damagesContentCells() {
      return contentCells(this.openPreviewModal);
    },
    damagesFacets: () => [
      {
        type: 'refinementList',
        props: {
          title: 'Name',
          attributeName: 'case_type_internal_name',
          toggled: false,
        },
      },
    ],
    damagesFilter() {
      return `vehicle.uuid:${this.vehicleUuid}`;
    },
  },
  created() {
    this.clearData([]);
    this.DAMAGES_INDEX = ALGOLIA_INDEXES.vehicleDamages;
    this.fleet = fleet;
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.damagesActions = [
      {
        label: 'Edit damage',
        method: ({ uuid, vehicle }) => {
          this.$router.push(`/fleet/vehicles/${vehicle.uuid}/vehicle-damages/${uuid}`);
        },
      },
      {
        label: 'Delete damage',
        type: 'danger',
        class: 'emobg-color-danger emobg-font-weight-semibold',
        isVisible: damage => !isSystemUser(getValue(damage, 'originator_user.uuid', null)),
        method: damage => {
          this.damageToDelete = damage;
          this.isDeleteModalVisible = true;
        },
      },
    ];
  },
  async mounted() {
    await this.getDamages(this.vehicleUuid);

    if (this.refreshList) {
      this.refreshAlgoliaList();
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.vehicle.details.damages, [
      'getDamages',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.vehicle.details.damages, [
      'clearData',
    ]),
    getValue,
    async refreshAlgoliaList() {
      this.isLoading = true;
      await this.getDamages(this.vehicleUuid);
      refreshAlgoliaStore(this.$refs.damages, DELAY.extraLong, () => { this.isLoading = false; });
    },
    openPreviewModal(imageUrl) {
      this.imageToPreview = imageUrl;
      this.isPreviewModalOpen = true;
    },
    closeModal() {
      this.imageToPreview = null;
      this.isPreviewModalOpen = false;
      this.isDeleteModalVisible = false;
      this.damageToDelete = null;
    },
  },
};
</script>

<template>
  <div class="DetailsDamagesView">
    <DamagesComponent
      :damages="damages"
      :sides="sides"
      class="py-3"
    />
    <div class="d-flex my-2 justify-content-between align-items-center">
      <h1>
        Vehicle damages
      </h1>

      <div>
        <ui-button
          style="min-width: fit-content;"
          data-test-id="create_damage-button"
          @clickbutton="$router.push({ name: fleet.damages.create, params: { vehicleUuid: getValue(generalData, 'uuid', null) } })"
        >
          Report new damage
        </ui-button>
      </div>
    </div>

    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
      />
      <MuiAlgoliaList
        ref="damages"
        :item-actions="damagesActions"
        :filters="damagesFilter"
        :index="DAMAGES_INDEX"
        :table-config="damagesContentCells"
        :is-search-enabled="false"
        is-export-enabled
        class="DetailsDamagesView__list"
      />
    </div>

    <PreviewDamageImageModal
      v-if="isPreviewModalOpen"
      :image="imageToPreview"
      @closeModal="closeModal"
    />

    <DeleteDamageModal
      v-if="isDeleteModalVisible"
      :damage="damageToDelete"
      :on-delete-damage="refreshAlgoliaList"
      @closeModal="closeModal"
    />
  </div>
</template>
<style lang="scss">
.DetailsDamagesView {
  &__list {
    .handy-scroll {
      z-index: 1;
    }

    .collapsed-cell {
      min-width: 200px;
    }
  }
}
</style>
