export const VEHICLE_TYPES = {
  car: 'car',
  utility: 'utility',
  van: 'van',
};

export const FUEL_TYPES = {
  diesel: 'diesel',
  electric: 'electric',
  gasoline: 'gasoline',
  hybrid: 'hybrid',
  lpg: 'lpg',
  plugInHybrid: 'plug_in_hybrid',
};
