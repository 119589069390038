<script>
import defaultsDeep from 'lodash/defaultsDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { DELAY } from '@emobg/web-utils';
import { mapActions, mapState } from 'vuex';
import { MuiValidationWrapper } from '@emobg/motion-ui/v1';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { VEHICLE_DETAILS_SCOPES } from '@domains/Carsharing/Vehicles/Details/store/VehiclesDetailsModule';
import { Cancel } from '@/constants/modalButtons.const';
import {
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { NOTIFICATION_TYPES } from '@/constants/notifications';

export default {
  name: 'VehicleLocationRequestModal',
  components: {
    GenericModalComponent,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  data() {
    return {
      isFormValid: false,
      reasons: [],
      inputs: {
        reasonUuid: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      isGpsRequestLoading: state => state.position.STATUS.LOADING,
      isGpsRequestError: state => state.position.STATUS.ERROR,
      vehicle: state => state.general.data,
    }),
    modalButtons() {
      return [
        defaultsDeep(
          {
            listeners: {
              click: () => this.$emit('closeModal'),
            },
            attributes: {
              'data-test-id': 'vehicle_location_modal-cancel-button',
            },
          },
          Cancel,
        ),
        {
          attributes: {
            form: 'vehicleLocationRequestForm',
            type: 'submit',
            loading: this.isGpsRequestLoading,
            disabled: !this.isFormValid,
            'data-test-id': 'vehicle_location_modal-confirm-button',
          },
          text: 'Confirm',
        },
      ];
    },
    isReasonSelected() {
      return !isEmpty(this.inputs.reasonUuid);
    },
  },
  async created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.VEHICLE_DETAILS_SCOPES = VEHICLE_DETAILS_SCOPES;

    try {
      const vehicleLocationRequestReasons = await this.$algolia.fetchIndex('vehicle_location_request_reasons');
      this.reasons = vehicleLocationRequestReasons.hits;
    } catch (error) {
      this.$throwError({
        key: null,
        message: 'Error fetching vehicle location request reasons',
      });
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.vehicle.details.information, ['getGpsPosition']),
    async onSubmit() {
      const previousInBooking = get(this.vehicle, 'location.in_booking');
      await this.getGpsPosition({ vehicleUuid: this.vehicle.uuid, reasonUuid: this.inputs.reasonUuid });
      const actualInBooking = get(this.vehicle, 'location.in_booking');

      if (!this.isGpsRequestError) {
        if (actualInBooking) {
          this.$notify({
            message: 'Vehicle location shown.',
            textAction: '',
          });
        } else if (previousInBooking) {
          this.$notify({
            message: 'Not required. Booking finished.',
            delay: DELAY.extraLong,
            textAction: '',
            type: NOTIFICATION_TYPES.info,
          });
        }
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :size="SIZES.small"
    :buttons="modalButtons"
    title="Request vehicle location"
    class="VehicleLocationRequestModal"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.carsharing.vehicle.details.information"
        :scope="VEHICLE_DETAILS_SCOPES.position"
        action="Show vehicle location"
      />
    </template>
    <template #body>
      <MuiValidationWrapper
        id="vehicleLocationRequestForm"
        :extra-conditions="[isReasonSelected]"
        @areAllValid="valid => isFormValid = valid"
        @submit="onSubmit"
      >
        <p class="d-flex align-items-center mb-4">
          <span>Provide reason</span>
          <ui-tooltip
            tooltip="Select one of the reasons below to see the vehicle location."
            class="ml-1"
          >
            <ui-icon
              :size="ICONS_SIZES.small"
              :icon="ICONS.infoFull"
              class="emobg-color-ink-light emobg-color-ink-hover"
            />
          </ui-tooltip>
        </p>
        <ui-radio
          v-for="(reason, index) in reasons"
          :key="reason.uuid"
          :value="inputs.reasonUuid"
          :data-test-id="`vehicle_location_modal-reason_${index}`"
          :option="reason.uuid"
          :caption="reason.name"
          class="d-block mb-2"
          name="reason"
          @changevalue="() => inputs.reasonUuid = reason.uuid"
        />
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
