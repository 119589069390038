import { isNullValue } from '@emobg/web-utils';
import toNumber from 'lodash/toNumber';
import {
  toDecimalFormat,
  toNumberUtil,
} from '@/utils';

export const fromDecimalToCents = value => {
  const price = toNumberUtil(toDecimalFormat(value), true, true);
  const priceToCents = !isNullValue(price) && price !== '' ? Math.round(price * 100) : null;
  return !Number.isNaN(priceToCents) ? priceToCents : null;
};

export const fromCentsToDecimals = (cents, dotFormat = false) => {
  const priceNormalized = cents / 100;
  return !Number.isNaN(priceNormalized) && !isNullValue(cents) ? toDecimalFormat(priceNormalized, { dotFormat }) : null;
};

export const toDecimalFormatOrNull = value => {
  const decimalNumber = toDecimalFormat(value);
  const number = toNumberUtil(decimalNumber);
  const isNotNumberOrIsZero = Number.isNaN(toNumber(number)) || number === 0;

  return isNotNumberOrIsZero ? null : decimalNumber;
};
