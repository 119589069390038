<script>
import filter from 'lodash/filter';
import get from 'lodash/get';
import head from 'lodash/head';
import intersection from 'lodash/intersection';
import { mapState } from 'vuex';
import { navigationErrorHandler } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { FUEL_TYPES } from '@domains/Fleet/VehicleModels/const/index.const';
import { PageTabs, PageView } from '@/components';
import fleetRoutes from '../../../router/FleetRouterMap';

export default {
  name: 'DetailsLogsView',
  components: {
    PageTabs,
    PageView,
  },
  data() {
    return {
      areTabsReady: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userPermissions: state => get(state, 'user.data.permissions', []),
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      fuelType: state => get(state, 'general.data.model.fuel_type'),
      isLoaded: state => state.general.STATUS.LOADED,
    }),
    isElectric() {
      return this.fuelType === FUEL_TYPES.electric;
    },
  },
  watch: {
    fuelType() {
      this.generateTabs();
      this.updateTabsVisibility();
    },
  },
  mounted() {
    this.generateTabs();
    this.updateTabsVisibility();
  },
  methods: {
    updateTabsVisibility() {
      this.areTabsReady = false;
      this.tabs = filter(this.tabs, item => intersection(this.userPermissions, item.permissions).length);

      const filteredTabs = filter(this.tabs, ['url', this.$route.name]);

      if (!filteredTabs.length) {
        const firstTab = get(head(this.tabs), 'url');
        this.$router.push({ name: firstTab }).catch(navigationErrorHandler);
      }

      this.areTabsReady = true;
    },
    generateTabs() {
      this.tabs = [
        {
          label: {
            title: 'Events',
          },
          url: fleetRoutes.vehicles.detail.logs.event,
          permissions: [CARSHARING_PERMISSIONS.viewCsVehicleLogs],
        },
        {
          label: {
            title: this.isElectric ? 'Traction battery' : 'Fuel',
          },
          url: this.isElectric ? fleetRoutes.vehicles.detail.logs.tractionBatteryLog : fleetRoutes.vehicles.detail.logs.fuelLog,
          permissions: [CARSHARING_PERMISSIONS.viewCsVehicleLogs],
        },
        {
          label: {
            title: 'Connectivity',
          },
          url: fleetRoutes.vehicles.detail.logs.connectivity,
          permissions: [CARSHARING_PERMISSIONS.viewCsVehicleLogs],
        },
        {
          label: {
            title: 'Vehicle location requests',
          },
          url: fleetRoutes.vehicles.detail.logs.vehicleLocationRequests,
          permissions: [CARSHARING_PERMISSIONS.viewCsVehicleLogs],
        },
      ];
      if (this.isElectric) {
        this.tabs.splice(2, 0, {
          label: {
            title: 'Charging status',
          },
          url: fleetRoutes.vehicles.detail.logs.chargingStatus,
          permissions: [CARSHARING_PERMISSIONS.viewCsVehicleLogs],
        });
      }
    },
  },
};
</script>

<template>
  <div
    v-if="isLoaded"
    class="DetailsLogsView"
  >
    <PageTabs
      v-if="areTabsReady"
      :tabs="tabs"
      are-inner-tabs
    />
    <PageView is-inner-tab-content>
      <RouterView />
    </PageView>
  </div>
</template>
