<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { FilterTableComponent } from '@/components';
import { fuelContentCells } from '../config/logsContentCells';
import { FUEL_SCHEMA } from '../config/fuelTableConfig';
import { VEHICLE_LOGS_SCOPES } from '../store/LogsModule';

export default {
  name: 'TractionBatteryView',
  components: {
    FilterTableComponent,
    MuiAlgoliaList,
  },
  data() {
    return {
      vehicleUuid: get(this, '$route.params.vehicle_uuid'),
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'operatorTimezone',
    ]),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      isConnectedCarPlatform: state => !!state.general.data.is_connected_car_platform,
      licensePlate: state => state.general.data.license_plate,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.logs, {
      tractionBatteryLogData: state => state[VEHICLE_LOGS_SCOPES.tractionBatteryLogs].data,
      tractionBatteryLogLoading: state => state[VEHICLE_LOGS_SCOPES.tractionBatteryLogs].STATUS.LOADING,
    }),
    exportColumns() {
      const titleAttributeName = this.isConnectedCarPlatform ? 'header' : 'title';
      const templateAttributeName = this.isConnectedCarPlatform ? 'template' : 'transformResult';
      const exportableSchema = {
        [titleAttributeName]: 'License plate',
        [templateAttributeName]: () => this.licensePlate,
      };
      return [
        ...this.fuelSchema,
        exportableSchema,
      ];
    },
    fuelSchema() {
      if (this.isConnectedCarPlatform) {
        return FUEL_SCHEMA({
          timezone: this.operatorTimezone,
          isElectric: true,
        });
      }
      return fuelContentCells(true);
    },
  },
  async created() {
    this.TELEMETRY_FUEL_INDEX = ALGOLIA_INDEXES.vtIcsTelemetryFuel;

    if (this.isConnectedCarPlatform) {
      await this.getTractionBatteryLog({ vehicleUuid: this.vehicleUuid });
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.vehicle.details.logs, [
      'getTractionBatteryLog',
    ]),
  },
};
</script>

<template>
  <div class="TractionBatteryView">
    <h1 class="mb-2">
      Traction battery
    </h1>
    <FilterTableComponent
      v-if="isConnectedCarPlatform"
      :schema="fuelSchema"
      :data="tractionBatteryLogData"
      :export-schema="exportColumns"
      :is-loading="tractionBatteryLogLoading"
      exportable
      export-name="traction-battery-log"
    />
    <MuiAlgoliaList
      v-else
      :filters="`vehicle_id:${vehicleUuid}`"
      :index="TELEMETRY_FUEL_INDEX"
      :table-config="fuelSchema"
      :query-parameters="{ hitsPerPage: 20 }"
      :export-columns="exportColumns"
      is-sidebar-collapsed
      is-export-enabled
    />
  </div>
</template>
