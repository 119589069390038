var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "EditEquipmentComponent row" },
    _vm._l(_vm.payload.equipment, function (item) {
      return _c(
        "div",
        {
          key: item.equipment_uuid,
          staticClass: "col-6 col-md-4 d-flex align-items-center",
        },
        [
          _c("ui-icon", {
            attrs: {
              icon: _vm.snakeCase(item.internal_name),
              size: _vm.ICONS_SIZES.small,
              color: _vm.GRAYSCALE.ink,
            },
          }),
          _c("ui-toggle", {
            staticClass: "pl-2 py-2 d-block",
            attrs: {
              value: item.checked,
              text: _vm.sentenceCase(item.name),
              "data-test-id": "active-toggle",
              name: "deviceStatus",
            },
            on: { changevalue: ({ detail }) => (item.checked = detail) },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }