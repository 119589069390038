var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LogsEventsView" },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Vehicle events ")]),
      _c("MuiAlgoliaList", {
        attrs: {
          "export-columns": _vm.contentCells,
          facets: _vm.transceiverEventsFacets,
          filters: `vehicle_id: ${_vm.vehicleUuid}`,
          index: _vm.TRANSCEIVERS_INDEX,
          "table-config": _vm.contentCells,
          "is-export-enabled": "",
          "is-sidebar-collapsed": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }