import get from 'lodash/get';

export const vehicleInformationCells = [
  {
    value: 'license_plate',
  },
  {
    value: ({ model }) => `${get(model, 'brand', '')} ${get(model, 'name', '')} (${get(model, 'fuel_type', '')})`,
    label: 'Model',
  },
  {
    value: 'chassis_number',
    label: 'Chassis number (VIN)',
  },
  {
    value: 'cs_operator_name',
    label: 'CS operator',
  },
  {
    value: 'category.name',
    label: 'Vehicle category',
  },
  {
    value: 'branding',
  },
  {
    value: 'color',
    label: 'Colour',
  },
  {
    value: 'seats',
    label: 'Number of seats',
  },
  {
    value: 'seats_color',
    label: 'Seat colour',
  },
  {
    value: 'transmission',
  },
  {
    value: 'engine',
  },
  {
    value: 'horse_power',
    label: 'Horse power (hp)',
  },
  {
    value: 'co2_emission',
    label: 'CO2 emission (g/km)',
  },
  {
    value: 'fuel_consumption',
    label: 'Fuel consumption (km/l)',
  },
];
