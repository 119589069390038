var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LocationRequestsView" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", [_vm._v(" Vehicle location requests ")]),
          _c(
            "ui-tooltip",
            {
              staticClass: "ml-1",
              attrs: {
                tooltip:
                  "Requests to see the vehicle location on the map during the booking",
              },
            },
            [
              _c("ui-icon", {
                staticClass: "emobg-color-ink-light emobg-color-ink-hover",
                attrs: {
                  icon: _vm.ICONS.infoFull,
                  size: _vm.ICONS_SIZES.medium,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("MuiAlgoliaList", {
        attrs: {
          "export-columns": _vm.exportColumns,
          filters: `vehicle_uuid:${_vm.vehicleUuid}`,
          "table-config": _vm.contentCells,
          facets: _vm.facets,
          index: _vm.VEHICLES_LOCATION_REQUESTS_INDEX,
          "is-export-enabled": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }