var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TractionBatteryView" },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Traction battery ")]),
      _vm.isConnectedCarPlatform
        ? _c("FilterTableComponent", {
            attrs: {
              schema: _vm.fuelSchema,
              data: _vm.tractionBatteryLogData,
              "export-schema": _vm.exportColumns,
              "is-loading": _vm.tractionBatteryLogLoading,
              exportable: "",
              "export-name": "traction-battery-log",
            },
          })
        : _c("MuiAlgoliaList", {
            attrs: {
              filters: `vehicle_id:${_vm.vehicleUuid}`,
              index: _vm.TELEMETRY_FUEL_INDEX,
              "table-config": _vm.fuelSchema,
              "query-parameters": { hitsPerPage: 20 },
              "export-columns": _vm.exportColumns,
              "is-sidebar-collapsed": "",
              "is-export-enabled": "",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }