import get from 'lodash/get';

import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatUtc,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { LogEventTemplate } from '@/templates';

export const eventsContentCells = operatorTimezone => [
  {
    attributeName: 'meta.name',
    title: 'Name',
    displayPriority: 1,
    minWidth: 350,
    transformValue: sentenceCase,
  },
  {
    attributeName: 'data.occurred_on_RFC3339',
    title: 'Occurred on',
    displayPriority: 1,
    minWidth: 120,
    transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
  },
  {
    attributeName: 'meta.created_at',
    title: 'Created on',
    displayPriority: 1,
    minWidth: 120,
    transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
  },
  {
    displayPriority: 0,
    type: RECORD_TYPES.component,
    component: LogEventTemplate,
    props: result => ({ section: get(result, 'data.badge'), attributes: ['number', 'serial_number', 'technology'], title: 'Badge' }),
  },
  {
    displayPriority: 0,
    type: RECORD_TYPES.component,
    component: LogEventTemplate,
    props: result => ({ section: get(result, 'data.device'), attributes: ['id', 'phone', 'firmware', 'qnr'], title: 'Device' }),
  },
  {
    displayPriority: 0,
    type: RECORD_TYPES.component,
    component: LogEventTemplate,
    props: result => ({ section: get(result, 'data.booking'), attributes: ['id'], title: 'Booking' }),
  },
  {
    displayPriority: 0,
    type: RECORD_TYPES.component,
    component: LogEventTemplate,
    props: result => ({ section: get(result, 'data.vehicle'), attributes: ['id'], title: 'Vehicle' }),
  },
];

export const fuelContentCells = fuelType => [
  {
    attributeName: 'occurred_on',
    title: 'Date',
    displayPriority: 1,
    minWidth: 150,
    transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended),
  },
  {
    attributeName: 'fuel_level',
    title: fuelType ? 'Traction battery level' : 'Fuel level',
    minWidth: 750,
    displayPriority: 1,
    transformValue: result => (result ? `${result} %` : FALLBACK_MESSAGE.noData),
  },
];
