var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DetailsBookingsView" },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Vehicle bookings ")]),
      _c("MuiAlgoliaList", {
        attrs: {
          "export-columns": _vm.contentCells,
          facets: _vm.bookingListFacets,
          filters: _vm.bookingListFilters,
          index: _vm.BOOKINGS_INDEX,
          "table-config": _vm.contentCells,
          "is-export-enabled": "",
          "is-sidebar-collapsed": "",
          "is-sidebar-hidden": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }