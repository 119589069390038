<script>
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { getValue } from '@emobg/web-utils';

import { DAMAGE_BLUEPRINT_SIDE_NAMES } from '../../../VehicleDamages/damages.const';

export default {
  name: 'DamagesComponent',
  props: {
    damages: {
      type: Array,
      default: () => [],
    },
    sides: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    transformDamages() {
      const grouped = groupBy(this.damages, 'side');
      const mappedDamages = map(this.sides, sides => ({ ...sides, label: getValue(DAMAGE_BLUEPRINT_SIDE_NAMES, `${sides.side}.label`, sides.side), damages: [grouped[sides.side]] }));

      return sortBy(mappedDamages, damage => getValue(DAMAGE_BLUEPRINT_SIDE_NAMES, `${damage.side}.position`, 0));
    },
  },
};
</script>

<template>
  <div class="DamagesComponent row">
    <div
      v-for="side in transformDamages"
      :key="side.name"
      class="col-md-3"
    >
      <div class="position-relative">
        <img
          :src="side.url"
          class="w-100"
          alt="side"
        >
        <div
          v-for="(damage, index) in side.damages"
          :key="index"
        >
          <div
            v-for="marker in damage"
            :key="marker.id"
            :style="{
              'top': `${marker.coordinate_y}%`,
              'left': `${marker.coordinate_x}%`,
            }"
            class="DamagesComponent__marker circle bg-color-danger position-absolute"
          />
        </div>
      </div>
      <div class="emobg-font-weight-semibold text-center">
        {{ side.label }}
      </div>
    </div>
  </div>
</template>
