<script>
import isNil from 'lodash/isNil';
import { CHARGING_STATUS_TEXTS } from '../../const/chargingStatus.const';

export default {
  name: 'ChargingStatus',
  props: {
    status: {
      type: Boolean,
      default: undefined,
    },
  },
  computed: {
    badgeConfig() {
      if (isNil(this.status)) {
        return {
          text: CHARGING_STATUS_TEXTS.noData,
          color: this.COLORS.warning,
        };
      }

      return {
        text: this.status ? CHARGING_STATUS_TEXTS.charging : CHARGING_STATUS_TEXTS.notCharging,
        color: this.status ? this.COLORS.success : this.GRAYSCALE.ground,
      };
    },
  },
};
</script>

<template>
  <ui-badge v-bind="badgeConfig" />
</template>
