var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "VehicleLocation emobg-border-radius-small emobg-border-color-ground-light emobg-border-1",
    },
    [
      _c("GoogleMapZonesComponent", {
        attrs: {
          "map-type-control": false,
          zones: _vm.allocationZone,
          "marker-sources": _vm.mapMarkers,
          "is-static": "",
          "street-view-control": "",
          "full-screen-control": "",
        },
      }),
      _c(
        "div",
        {
          class: [
            "d-flex justify-content-center align-items-center p-3",
            {
              "flex-column": _vm.get(_vm.vehicle, "location.in_booking"),
            },
          ],
        },
        [
          _vm.get(_vm.vehicle, "location.timestamp")
            ? _c(
                "div",
                { staticClass: "emobg-caption-1 emobg-color-ink-light mr-2" },
                [
                  _vm._v(
                    " Updated: " +
                      _vm._s(
                        _vm.formatUtc(
                          _vm.vehicle.location.timestamp,
                          _vm.DATE_FORMAT.defaultExtended,
                          _vm.operatorTimezone
                        )
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.hasPermissions(
            _vm.CARSHARING_PERMISSIONS.viewCsVehicleCurrentLocation
          )
            ? [
                _vm.get(_vm.vehicle, "location.in_booking")
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "p",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _vm._v(" Active booking. "),
                            _vm.isVehicleShown
                              ? _c(
                                  "span",
                                  { staticClass: "emobg-color-success" },
                                  [_vm._v(" Vehicle shown ")]
                                )
                              : [_vm._v(" Vehicle hidden ")],
                            _c(
                              "ui-tooltip",
                              {
                                staticClass: "ml-1",
                                attrs: { tooltip: _vm.tooltipRequestButton },
                              },
                              [
                                _c("ui-icon", {
                                  staticClass:
                                    "emobg-color-ink-light emobg-color-ink-hover",
                                  attrs: {
                                    size: _vm.ICONS_SIZES.small,
                                    icon: _vm.ICONS.infoFull,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                        _c(
                          "ui-button",
                          {
                            staticClass: "mt-1",
                            attrs: {
                              disabled: _vm.isGpsRequestLoading,
                              face: _vm.FACES.outline,
                              "data-test-id": "vehicle_location-request-button",
                            },
                            on: { clickbutton: _vm.getPosition },
                          },
                          [_vm._v(" Request vehicle location ")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "ui-button",
                      {
                        staticClass: "d-inline-block pl-0 pr-0 min-width--none",
                        attrs: {
                          face: _vm.FACES.text,
                          loading: _vm.isGpsRequestLoading,
                          "data-test-id": "vehicle_location-request-button",
                        },
                        on: { clickbutton: _vm.getPosition },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("ui-icon", {
                              staticClass: "mr-2",
                              attrs: {
                                icon: _vm.ICONS.refresh,
                                size: _vm.ICONS_SIZES.medium,
                              },
                            }),
                            _c("span", { staticClass: "emobg-caption-1" }, [
                              _vm._v("Refresh"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.isModalOpen
        ? _c("VehicleLocationRequestModal", {
            on: {
              closeModal: function ($event) {
                _vm.isModalOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }