var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BatteryLevel d-inline-flex align-items-center",
      attrs: { "data-test-id": "battery_level" },
    },
    [
      _c("ui-icon", {
        attrs: {
          icon: _vm.iconBatteryLevel,
          size: _vm.iconSize,
          color:
            _vm.iconBatteryLevel === _vm.ICONS.battery
              ? _vm.COLORS.danger
              : _vm.COLORS.ink,
          "data-test-id": "icon",
        },
      }),
      _c("span", { class: _vm.textClass }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.iconBatteryLevel !== _vm.ICONS.batteryEmpty
                ? `${_vm.batteryLevel}%`
                : _vm.fallbackMessage
            ) +
            " "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }