<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import moment from 'moment-timezone';

import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiSelect,
  Validate,
} from '@emobg/motion-ui/v1';
import {
  DATE_FORMAT,
  DATE_FORMAT_KEYS,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { fromCentsToDecimals } from '@domains/Pricing/utils/pricing.utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { PATTERN_INPUT_VALIDATIONS, toDecimalFormat, toNumberUtil } from '@/utils';
import editMixin from './editMixin';
import {
  FINANCING_MODE,
  JURIDIC_STATUS,
} from './const/editVehicleComponents';

export default {
  name: 'EditFinancialInfoComponent',
  directives: {
    Validate,
  },
  components: {
    MuiAlgoliaSelect,
    MuiInputText,
    MuiSelect,
  },
  mixins: [editMixin],
  props: {
    financial: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      originalPayload: null,
      payload: {
        bonus_amount: fromCentsToDecimals(get(this, 'financial.bonus_amount'), true),
        is_bonus_managed_by_renter: get(this, 'financial.bonus_managed_by_renter'),
        company_name: get(this, 'financial.company_name'),
        company_uuid: get(this, 'financial.company_uuid'),
        contract_number: get(this, 'financial.contract_number'),
        contracted_mileage: get(this, 'financial.contracted_mileage'),
        extra_mileage_cost: get(this, 'financial.extra_mileage_cost'),
        financing_mode: get(this, 'financial.financing_mode'),
        first_rent_amount_without_vat: fromCentsToDecimals(get(this, 'financial.first_rent_amount_without_vat'), true),
        is_flexible_contract: get(this, 'financial.flexible_contract'),
        insurance_carrier: get(this, 'financial.insurance_carrier'),
        juridic_status: get(this, 'financial.juridic_status'),
        leaser_company_name: get(this, 'financial.leaser_company_name'),
        leaser_company_uuid: get(this, 'financial.leaser_company_uuid'),
        months_warranty_duration: get(this, 'financial.months_warranty_duration'),
        rent_amount_without_vat: fromCentsToDecimals(get(this, 'financial.rent_amount_without_vat'), true),
        delivery_date: undefined,
        first_registration_date: undefined,
        juridic_end_date: undefined,
        juridic_start_date: undefined,
        order_date: undefined,
        sale_date: undefined,
      },
    };
  },
  computed: {
    hasSameValues() {
      return isEqual(this.payload, this.originalPayload);
    },
  },
  watch: {
    hasSameValues(value) {
      this.$emit('update:payload', value);
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.FINANCING_MODE = FINANCING_MODE;
    this.JURIDIC_STATUS = JURIDIC_STATUS;
    this.DATE_FORMAT = DATE_FORMAT;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.setDateConverted('delivery_date');
    this.setDateConverted('first_registration_date');
    this.setDateConverted('juridic_end_date');
    this.setDateConverted('juridic_start_date');
    this.setDateConverted('order_date');
    this.setDateConverted('sale_date');
    this.originalPayload = cloneDeep(this.payload);
  },
  methods: {
    map,
    toNumberUtil,
    toDecimalFormat,
    setDateConverted(section) {
      const date = get(this, `financial.${section}`);
      this.payload[section] = date ? moment(date, 'X').format(DATE_FORMAT.dob) : undefined;
    },
    formatDateTime(section, date) {
      this.payload[section] = formatUtc(date, DATE_FORMAT.dob);
    },
    sentenceCase,
  },
};
</script>

<template>
  <div
    class="EditFinancialInfoComponent row"
    data-test-id="financial_info-form"
  >
    <div class="col-sm-6 mb-4">
      <MuiSelect
        v-model="payload.financing_mode"
        v-validate="{
          isRequired: true,
        }"
        :options="map(FINANCING_MODE, (status) => ({ label: sentenceCase(status), value: status }))"
        class="w-100"
        placeholder="Financing mode"
        label="Financing mode*"
        name="financing_mode"
        data-test-id="financing_mode-select"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <MuiAlgoliaSelect
        v-model="payload.company_uuid"
        :index="ALGOLIA_INDEXES.companies"
        :title="result => result.commercial_name"
        :on-clear="() => null"
        show-clear
        path-value="uuid"
        placeholder="Select"
        label="Company"
        class="w-100"
        name="commercial_name"
        data-test-id="company-select"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <MuiAlgoliaSelect
        v-model="payload.leaser_company_uuid"
        :index="ALGOLIA_INDEXES.companies"
        :title="result => result.commercial_name"
        :on-clear="() => null"
        show-clear
        path-value="uuid"
        label="Leaser company"
        placeholder="Leaser company"
        class="w-100"
        name="leaser_company"
        data-test-id="leaser_company-select"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <ui-datetimepicker
        :size="SIZES.small"
        :date.prop="payload.order_date || undefined"
        :date-format-key="DATE_FORMAT_KEYS.dob"
        label="Order date"
        name="order_date"
        data-test-id="order_date-input"
        class="w-100"
        skiptime
        @datechanged="({ detail }) => (payload.order_date = detail ? detail.format(DATE_FORMAT.dob) : undefined)"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <ui-datetimepicker
        :size="SIZES.small"
        :date.prop="payload.delivery_date || undefined"
        :date-format-key="DATE_FORMAT_KEYS.dob"
        label="Delivery date"
        name="delivery_date"
        data-test-id="delivery_date-input"
        class="w-100"
        skiptime
        @datechanged="({ detail }) => (payload.delivery_date = detail ? detail.format(DATE_FORMAT.dob) : undefined)"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <ui-datetimepicker
        :size="SIZES.small"
        :date.prop="payload.sale_date || undefined"
        :date-format-key="DATE_FORMAT_KEYS.dob"
        label="Sale date"
        name="sale_date"
        data-test-id="sale_date-input"
        class="w-100"
        skiptime
        @datechanged="({ detail }) => (payload.sale_date = detail ? detail.format(DATE_FORMAT.dob) : undefined)"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <MuiInputText
        v-model="payload.contract_number"
        placeholder="Contract number"
        class="w-100"
        label="Contract number"
        name="contract_number"
      />
    </div>
    <div class="col-sm-6 mb-4">
      <ui-toggle
        :value="payload.is_flexible_contract"
        label="Is contract flexible"
        name="flexible_contract"
        data-test-id="flexible_contract-toggle"
        @changevalue="({ detail }) => payload.is_flexible_contract = detail"
      />
    </div>
    <div class="col-sm-6 mb-4">
      <ui-toggle
        :value="payload.is_bonus_managed_by_renter"
        label="Bonus managed by renter"
        name="bonus_managed_by_renter"
        data-test-id="bonus_managed_by_renter-toggle"
        @changevalue="({ detail }) => payload.is_bonus_managed_by_renter = detail"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <ui-datetimepicker
        :size="SIZES.small"
        :date.prop="payload.first_registration_date || undefined"
        :date-format-key="DATE_FORMAT_KEYS.dob"
        label="First registration date"
        name="first_registration_date"
        data-test-id="first_registration_date-input"
        class="w-100"
        skiptime
        @datechanged="({ detail }) => (payload.first_registration_date = detail ? detail.format(DATE_FORMAT.dob) : undefined)"
      />
    </div>

    <div class="col-sm-6">
      <MuiInputText
        v-model="payload.contracted_mileage"
        placeholder="Contracted mileage"
        label="Contracted mileage"
        class="w-100 mb-3"
        name="contracted_mileage"
        data-test-id="contracted_mileage-input"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <MuiInputText
        v-model="payload.months_warranty_duration"
        v-validate="{
          isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
        }"
        placeholder="Warranty duration (Months)"
        label="Warranty duration (Months)"
        class="w-100"
        name="months_warranty_duration"
        data-test-id="month_warranty_duration-input"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <MuiInputText
        v-model="payload.insurance_carrier"
        placeholder="Insurance carrier"
        label="Insurance carrier"
        class="w-100"
        name="insurance_carrier"
        data-test-id="insurance_carrier-input"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <MuiInputText
        v-model="payload.first_rent_amount_without_vat"
        v-validate="{
          isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
        }"
        placeholder="First rent amount without VAT"
        label="First rent amount without VAT"
        class="w-100"
        name="first_rent_amount_without_vat"
        data-test-id="first_rent_amount_without_vat-input"
        @blur="value => payload.first_rent_amount_without_vat = toDecimalFormat(value, { dotFormat: true })"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <MuiInputText
        v-model="payload.rent_amount_without_vat"
        v-validate="{
          isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
        }"
        placeholder="Rent amount without VAT"
        label="Rent amount without VAT"
        class="w-100"
        name="rent_amount_without_vat"
        data-test-id="rent_amount_without_vat-input"
        @blur="value => payload.rent_amount_without_vat = toDecimalFormat(value, { dotFormat: true })"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <MuiInputText
        v-model="payload.bonus_amount"
        v-validate="{
          isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
        }"
        placeholder="Bonus amount"
        label="Bonus amount"
        class="w-100"
        name="bonus_amount"
        data-test-id="bonus_amount-input"
        @blur="value => payload.bonus_amount = toDecimalFormat(value, { dotFormat: true })"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <MuiInputText
        v-model="payload.extra_mileage_cost"
        v-validate="{
          isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
        }"
        placeholder="Extra mileage cost"
        label="Extra mileage cost"
        class="w-100"
        name="extra_mileage_cost"
        data-test-id="extra_mileage_cost-input"
        @blur="value => payload.extra_mileage_cost = toDecimalFormat(value, { dotFormat: true })"
      />
    </div>

    <div class="col-sm-6 mb-4">
      <MuiSelect
        v-model="payload.juridic_status"
        :options="map(JURIDIC_STATUS, (status) => ({ label: sentenceCase(status), value: status }))"
        :on-clear="() => null"
        show-clear
        class="w-100"
        placeholder="Juridic status"
        label="Juridic status"
        name="type"
        data-test-id="juridict_status-select"
      />
    </div>
    <div class="col-sm-6 mb-4">
      <ui-datetimepicker
        :size="SIZES.small"
        :date.prop="payload.juridic_start_date || undefined"
        :date-format-key="DATE_FORMAT_KEYS.dob"
        label="Juridic start date"
        name="juridic_start_date"
        data-test-id="juridic_start_date-input"
        class="w-100"
        skiptime
        @datechanged="({ detail }) => (payload.juridic_start_date = detail ? detail.format(DATE_FORMAT.dob) : undefined)"
      />
    </div>
    <div class="col-sm-6 mb-4">
      <ui-datetimepicker
        :size="SIZES.small"
        :date.prop="payload.juridic_end_date || undefined"
        :date-format-key="DATE_FORMAT_KEYS.dob"
        label="Juridic end date"
        name="juridic_end_date"
        data-test-id="juridic_end_date-input"
        class="w-100"
        skiptime
        @datechanged="({ detail }) => (payload.juridic_end_date = detail ? detail.format(DATE_FORMAT.dob) : undefined)"
      />
    </div>
  </div>
</template>
