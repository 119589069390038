<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import editMixin from './editMixin';

export default {
  name: 'EditStatusComponent',
  mixins: [editMixin],
  props: {
    status: {
      type: Object,
      default: () => ({}),
    },
    isIboxxWithoutDevice: {
      type: Boolean,
      required: true,
    },
    isNonConnected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      originalPayload: null,
      payload: {
        api: this.status.api,
        active: this.status.active,
      },
    };
  },
  computed: {
    hasSameValues() {
      return isEqual(this.payload, this.originalPayload);
    },
  },
  watch: {
    hasSameValues(value) {
      this.$emit('update:payload', value);
    },
  },
  created() {
    this.originalPayload = cloneDeep(this.payload);
  },
};
</script>

<template>
  <div class="EditStatusComponent row">
    <div class="col-6">
      <ui-toggle
        :value="payload.active"
        :disabled="isIboxxWithoutDevice"
        :text="`${payload.active ? 'Active' : 'Inactive'}`"
        name="isActive"
        label="Vehicle activation status"
        @changevalue="(event) => payload.active = event.detail"
      />
    </div>
    <div
      v-if="!isNonConnected"
      class="col-6"
    >
      <ui-toggle
        :value="payload.api"
        :text="`${payload.api ? 'Visible' : 'Invisible'}`"
        name="isVisible"
        label="Vehicle visibility status"
        @changevalue="(event) => payload.api = event.detail"
      />
    </div>
    <ui-alert
      v-if="isIboxxWithoutDevice"
      :color="COLORS.warning"
      :icon="ICONS.alertFull"
      class="col-6 mt-3 d-block"
    >
      Select <span class="emobg-font-weight-semibold">Device number</span> in hardware section to make the vehicle
      <span class="emobg-font-weight-semibold">Active</span>
    </ui-alert>
  </div>
</template>
