import isNil from 'lodash/isNil';
import { DATE_FORMAT, FALLBACK_MESSAGE, formatUtc } from '@emobg/web-utils';
import { CHARGING_STATUS_TEXTS } from '../../../../const/chargingStatus.const';
import ChargingStatus from '../../../../components/ChargingStatus/ChargingStatus';

export const CHARGE_STATUS_SCHEMA = ({ timezone }) => [
  {
    header: 'First occurrence',
    template: ({ firstOccurrence }) => (
      firstOccurrence ? formatUtc(firstOccurrence, DATE_FORMAT.defaultExtended, timezone) : FALLBACK_MESSAGE.dash
    ),
  },
  {
    header: 'Last occurrence',
    template: ({ lastOccurrence }) => (
      lastOccurrence ? formatUtc(lastOccurrence, DATE_FORMAT.defaultExtended, timezone) : FALLBACK_MESSAGE.dash
    ),
  },
  {
    header: 'Charge status',
    template: ({ value: status }) => {
      if (isNil(status)) {
        return CHARGING_STATUS_TEXTS.noData;
      }
      return status ? CHARGING_STATUS_TEXTS.charging : CHARGING_STATUS_TEXTS.notCharging;
    },
    component: ChargingStatus,
    props: ({ value: status }) => ({
      status,
    }),
  },
];

