import { sentenceCase } from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS } from '@emobg/vue-base';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export const allocationContentCells = () => [
  {
    attributeName: 'start',
    title: 'Start date',
    displayPriority: 1,
    minWidth: 180,
  },
  {
    attributeName: 'end',
    title: 'End date',
    displayPriority: 1,
    minWidth: 180,
  },
  {
    attributeName: 'location_name',
    title: 'Location name',
    displayPriority: 1,
    minWidth: 180,
  },
  {
    attributeName: 'location_address',
    title: 'Address',
    displayPriority: 1,
    minWidth: 150,
  },
  {
    attributeName: 'location_neighborhood',
    title: 'Neighborhood',
    displayPriority: 1,
    minWidth: 120,
  },
  {
    attributeName: 'location_city',
    title: 'City',
    displayPriority: 1,
    minWidth: 120,
  },
  {
    attributeName: 'location_type',
    title: 'Type',
    displayPriority: 2,
    transformValue: sentenceCase,
    minWidth: 120,
  },
  {
    title: 'Location status',
    displayPriority: 0,
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: result => ({
      text: result.location_active ? 'Active' : 'Inactive',
      color: result.location_active ? COLORS.success : COLORS.danger,
    }),
  },
  {
    attributeName: 'location_open_location',
    title: 'Open location',
    displayPriority: 0,
    transformValue: value => (value ? 'Yes' : 'No'),
  },
  {
    attributeName: 'location_api',
    title: 'Location visible to users',
    displayPriority: 0,
    transformValue: value => (value ? 'Yes' : 'No'),
  },
];
