var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "EditGeneralInfoComponent row" }, [
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("MuiInputText", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isRequired: true,
                },
                expression: "{\n          isRequired: true,\n        }",
              },
            ],
            attrs: {
              label: "License plate*",
              name: "licensePlate",
              placeholder: "Enter plate",
              "data-test-id": "license_plate-input",
            },
            model: {
              value: _vm.payload.license_plate,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "license_plate", $$v)
              },
              expression: "payload.license_plate",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("MuiAlgoliaSelect", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isRequired: true,
                },
                expression: "{\n          isRequired: true,\n        }",
              },
            ],
            staticClass: "w-100 text-capitalize",
            attrs: {
              index: _vm.ALGOLIA_INDEXES.vehicleModels,
              title: (model) =>
                `${model.brand.name} ${model.name} (${model.fuel_type})`,
              "path-value": "uuid",
              label: "Model*",
              name: "model",
              placeholder: "Select",
              "data-test-id": "model-select",
            },
            model: {
              value: _vm.payload.vehicle_model_uuid,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "vehicle_model_uuid", $$v)
              },
              expression: "payload.vehicle_model_uuid",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("MuiInputText", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.CHASSIS_VALIDATION,
                expression: "CHASSIS_VALIDATION",
              },
            ],
            attrs: {
              maxlength: "17",
              label: "Chassis number (VIN)*",
              name: "chassisNumber",
              placeholder: "Enter number value",
              "data-test-id": "chassis_number-input",
            },
            on: { input: _vm.transformChassisToUpperCase },
            model: {
              value: _vm.payload.chassis_number,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "chassis_number", $$v)
              },
              expression: "payload.chassis_number",
            },
          }),
          _vm.hasChassisNumberChanged
            ? _c(
                "ui-alert",
                {
                  staticClass: "mt-2 d-block",
                  attrs: {
                    color: _vm.COLORS.primary,
                    icon: _vm.ICONS.infoFull,
                  },
                },
                [
                  _c("p", { staticClass: "emobg-body-1" }, [
                    _vm._v(" Make sure this "),
                    _c("span", { staticClass: "emobg-body-2" }, [
                      _vm._v("chassis number"),
                    ]),
                    _vm._v(
                      " is right, to ensure connectivity and correct vehicle management "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("MuiAlgoliaSelect", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isRequired: true,
                },
                expression: "{\n          isRequired: true,\n        }",
              },
            ],
            staticClass: "w-100",
            attrs: {
              index: _vm.ALGOLIA_INDEXES.csOperators,
              title: (operator) => operator.name,
              filters: _vm.getOperatorFilter({ attribute: "id" }),
              "path-value": "uuid",
              label: "CS Operator*",
              name: "operator",
              placeholder: "Select the CS Operator",
              "data-test-id": "operator-select",
            },
            model: {
              value: _vm.payload.cs_operator_uuid,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "cs_operator_uuid", $$v)
              },
              expression: "payload.cs_operator_uuid",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("MuiAlgoliaSelect", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isRequired: true,
                },
                expression: "{\n          isRequired: true,\n        }",
              },
            ],
            staticClass: "w-100",
            attrs: {
              index: _vm.ALGOLIA_INDEXES.vehicleCategories,
              filters: `cs_operator_uuid:${_vm.payload.cs_operator_uuid} AND active: 1`,
              title: (result) => result.name,
              "path-value": "uuid",
              label: "Vehicle category*",
              name: "category",
              placeholder: "Select category",
              "data-test-id": "category-select",
            },
            model: {
              value: _vm.payload.vehicle_category_uuid,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "vehicle_category_uuid", $$v)
              },
              expression: "payload.vehicle_category_uuid",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("MuiSelect", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isRequired: true,
                },
                expression: "{\n          isRequired: true,\n        }",
              },
            ],
            staticClass: "w-100 text-capitalize",
            attrs: {
              options: _vm.values(_vm.BRANDING),
              label: "Branding*",
              placeholder: "Select",
              name: "branding",
              "data-test-id": "branding-select",
            },
            model: {
              value: _vm.payload.branding,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "branding", $$v)
              },
              expression: "payload.branding",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("MuiSelect", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isRequired: true,
                },
                expression: "{\n          isRequired: true,\n        }",
              },
            ],
            staticClass: "w-100 text-capitalize",
            attrs: {
              options: _vm.values(_vm.VEHICLE_COLORS),
              label: "Colour*",
              placeholder: "Select",
              name: "color",
              "data-test-id": "color-select",
            },
            model: {
              value: _vm.payload.color,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "color", $$v)
              },
              expression: "payload.color",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("MuiSelect", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isRequired: true,
                },
                expression: "{\n          isRequired: true,\n        }",
              },
            ],
            staticClass: "w-100",
            attrs: {
              options: _vm.VEHICLE_SEATS_OPTIONS,
              label: "Number of seats*",
              name: "seats",
              placeholder: "Select a number of seats",
              "data-test-id": "seats-input",
            },
            model: {
              value: _vm.payload.seats,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "seats", $$v)
              },
              expression: "payload.seats",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("MuiSelect", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isRequired: true,
                },
                expression: "{\n          isRequired: true,\n        }",
              },
            ],
            staticClass: "w-100 text-capitalize",
            attrs: {
              options: _vm.values(_vm.SEATS_COLORS),
              label: "Seat colour*",
              placeholder: "Select",
              name: "seat_color",
              "data-test-id": "seats_color-input",
            },
            model: {
              value: _vm.payload.seats_color,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "seats_color", $$v)
              },
              expression: "payload.seats_color",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("MuiSelect", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isRequired: true,
                },
                expression: "{\n          isRequired: true,\n        }",
              },
            ],
            staticClass: "w-100 text-capitalize",
            attrs: {
              options: _vm.values(_vm.TRANSMISSION_TYPES),
              label: "Transmission*",
              placeholder: "Select",
              name: "transmission",
              "data-test-id": "transmission-select",
            },
            model: {
              value: _vm.payload.transmission,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "transmission", $$v)
              },
              expression: "payload.transmission",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("MuiInputText", {
            staticClass: "w-100",
            attrs: {
              placeholder: "Enter (e.g. TDI)",
              label: "Engine",
              name: "engine",
            },
            model: {
              value: _vm.payload.engine,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "engine", $$v)
              },
              expression: "payload.engine",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("MuiInputText", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isPattern: _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                },
                expression:
                  "{\n          isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n        }",
              },
            ],
            attrs: {
              label: "Horse power (hp)",
              name: "horse_power",
              placeholder: "Enter number value",
              "data-test-id": "horse_power-input",
            },
            model: {
              value: _vm.payload.horse_power,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "horse_power", _vm._n($$v))
              },
              expression: "payload.horse_power",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "col-6" },
      [
        _c("MuiInputText", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: {
                isPattern: _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
              },
              expression:
                "{\n        isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n      }",
            },
          ],
          attrs: {
            label: "CO2 emission (g/km)",
            name: "co2",
            placeholder: "Enter number value (e.g. 120.4)",
            "data-test-id": "co2-input",
          },
          on: {
            blur: (value) => (_vm.payload.co2_emission = _vm.toNumber(value)),
          },
          model: {
            value: _vm.payload.co2_emission,
            callback: function ($$v) {
              _vm.$set(_vm.payload, "co2_emission", _vm._n($$v))
            },
            expression: "payload.co2_emission",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-6" },
      [
        _c("MuiInputText", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: {
                isPattern: _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
              },
              expression:
                "{\n        isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n      }",
            },
          ],
          attrs: {
            label: "Fuel consumption (km/l)",
            name: "fuel",
            placeholder: "Enter number value (e.g. 6.3)",
            "data-test-id": "fuel-input",
          },
          on: {
            blur: (value) =>
              (_vm.payload.fuel_consumption = _vm.toNumber(value)),
          },
          model: {
            value: _vm.payload.fuel_consumption,
            callback: function ($$v) {
              _vm.$set(_vm.payload, "fuel_consumption", _vm._n($$v))
            },
            expression: "payload.fuel_consumption",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }