var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DetailsView" },
    [
      _vm.generalData
        ? _c(
            "PageView",
            { staticClass: "emobg-background-color-white" },
            [
              _c(
                "OverviewCollapse",
                {
                  attrs: { id: "vehicleOverview" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "emobg-font-large emobg-font-weight-semibold mr-2",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.generalData.license_plate) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "emobg-font-large emobg-font-weight-semibold mr-2",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.get(
                                            _vm.generalData,
                                            "model.brand"
                                          )
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.get(_vm.generalData, "model.name")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "emobg-caption-1 emobg-color-ink-light",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.get(
                                            _vm.generalData,
                                            "category.name"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1601285774
                  ),
                },
                [
                  _c("div", { staticClass: "row py-4" }, [
                    _c("div", { staticClass: "col-md-6 col-lg-3" }, [
                      _c("img", {
                        staticClass:
                          "w-75 pr-3 mb-4 vehicle__image align-bottom",
                        attrs: {
                          src: _vm.get(_vm.generalData, "model.image"),
                          alt: _vm.get(_vm.generalData, "model.brand"),
                        },
                      }),
                      _c("h3", { staticClass: "mb-1" }, [
                        _vm._v(
                          " " + _vm._s(_vm.generalData.license_plate) + " "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center emobg-body-2 mb-1",
                        },
                        [
                          _vm.isElectric
                            ? _c("ui-icon", {
                                staticClass: "electric-vehicle-icon-color",
                                attrs: { icon: _vm.ICONS.electric },
                              })
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(_vm.get(_vm.generalData, "model.brand")) +
                              " " +
                              _vm._s(_vm.get(_vm.generalData, "model.name")) +
                              " " +
                              _vm._s(
                                _vm.generalData.color
                                  ? `(${_vm.titleCase(_vm.generalData.color)})`
                                  : _vm.FALLBACK_MESSAGE.noData
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "emobg-color-ink-light mb-4" }, [
                        _c("p", { staticClass: "mb-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.get(_vm.generalData, "category.name")
                              ) +
                              " "
                          ),
                        ]),
                        _vm.isElectric
                          ? _c("p", [
                              _vm._v(
                                " Traction battery capacity: " +
                                  _vm._s(
                                    _vm.get(
                                      _vm.generalData,
                                      "model.fuel_capacity"
                                    )
                                      ? `${_vm.get(
                                          _vm.generalData,
                                          "model.fuel_capacity"
                                        )}kW`
                                      : _vm.FALLBACK_MESSAGE.noData
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("p", { staticClass: "mb-4" }, [
                        _c("span", { staticClass: "emobg-body-2" }, [
                          _vm._v("UUID:"),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "emobg-body-1" }, [
                          _vm._v(_vm._s(_vm.generalData.uuid)),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-lg-3" },
                      [
                        _c(
                          "ContentCellComponent",
                          {
                            staticClass: "mb-4",
                            attrs: {
                              label: "Connectivity",
                              "is-on-header": "",
                            },
                          },
                          [
                            _c("ConnectivityStatusComponent", {
                              staticClass: "my-2 emobg-body-2",
                              attrs: {
                                status: _vm.get(
                                  _vm.generalData,
                                  "telemetry.connectivity_status"
                                ),
                                "icon-size": _vm.ICONS_SIZES.large,
                                "is-non-connected": _vm.isNonConnected,
                                "show-tooltip": "",
                              },
                            }),
                            _vm.get(
                              _vm.generalData,
                              "telemetry.connectivity_date"
                            )
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "emobg-body-1 emobg-color-ink-light",
                                  },
                                  [
                                    _vm._v(
                                      " Updated: " +
                                        _vm._s(
                                          _vm.formatUtc(
                                            _vm.generalData.telemetry
                                              .connectivity_date,
                                            _vm.DATE_FORMAT.defaultExtended,
                                            _vm.operatorTimezone
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        !_vm.isNonConnected
                          ? [
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: _vm.isElectric
                                      ? "Traction battery"
                                      : "Fuel level",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        "d-flex align-items-center mb-1 v-body-1",
                                        `emobg-color-${_vm.getIconColor(
                                          "fuel_level"
                                        )}`,
                                      ],
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "emobg-body-2" },
                                        [
                                          _vm.isElectric
                                            ? _c("BatteryLevel", {
                                                attrs: {
                                                  "battery-level": _vm.get(
                                                    _vm.generalData,
                                                    "fuel_level"
                                                  ),
                                                  "danger-level": _vm.get(
                                                    _vm.generalData,
                                                    "model.fuel_danger_level",
                                                    0
                                                  ),
                                                  "icon-size":
                                                    _vm.ICONS_SIZES.xLarge,
                                                },
                                              })
                                            : [
                                                _c("ui-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    icon: _vm.ICONS
                                                      .fuelDispenser,
                                                    color:
                                                      _vm.getIconColor(
                                                        "fuel_level"
                                                      ),
                                                  },
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.get(
                                                          _vm.generalData,
                                                          "fuel_level"
                                                        )
                                                          ? `${_vm.generalData.fuel_level}%`
                                                          : _vm.FALLBACK_MESSAGE
                                                              .noData
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                  _vm.generalData.fuel_level_timestamp
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "emobg-body-1 emobg-color-ink-light mt-1",
                                        },
                                        [
                                          _vm._v(
                                            " Updated: " +
                                              _vm._s(
                                                _vm.formatUtc(
                                                  _vm.generalData
                                                    .fuel_level_timestamp,
                                                  _vm.DATE_FORMAT
                                                    .defaultExtended,
                                                  _vm.operatorTimezone
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isElectric
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "mt-1" },
                                          [
                                            _c("ChargingStatus", {
                                              attrs: {
                                                status: _vm.get(
                                                  _vm.generalData,
                                                  "telemetry.charging_status"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.has(
                                          _vm.generalData,
                                          "telemetry.charging_status_date"
                                        )
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "emobg-body-1 emobg-color-ink-light mt-1",
                                              },
                                              [
                                                _vm._v(
                                                  " Updated: " +
                                                    _vm._s(
                                                      _vm.formatUtc(
                                                        _vm.generalData
                                                          .telemetry
                                                          .charging_status_date,
                                                        _vm.DATE_FORMAT
                                                          .defaultExtended,
                                                        _vm.operatorTimezone
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: { label: "Battery level" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        "d-flex align-items-center mb-1",
                                        `emobg-color-${_vm.getIconColor(
                                          "battery_level"
                                        )}`,
                                      ],
                                    },
                                    [
                                      _c("ui-icon", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          icon: _vm.ICONS.carBattery,
                                          color:
                                            _vm.getIconColor("battery_level"),
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "emobg-body-2" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.generalData.battery_level
                                                  ? `${_vm.generalData.battery_level}V`
                                                  : _vm.FALLBACK_MESSAGE.noData
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.generalData.battery_level_timestamp
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "emobg-body-1 emobg-color-ink-light mt-1",
                                        },
                                        [
                                          _vm._v(
                                            " Updated: " +
                                              _vm._s(
                                                _vm.formatUtc(
                                                  _vm.generalData
                                                    .battery_level_timestamp,
                                                  _vm.DATE_FORMAT
                                                    .defaultExtended,
                                                  _vm.operatorTimezone
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-lg-3" },
                      [
                        _c(
                          "ContentCellComponent",
                          {
                            staticClass: "mb-4",
                            attrs: { label: "Status", "is-on-header": "" },
                          },
                          [
                            _c("ui-badge", {
                              attrs: {
                                text: _vm.generalData.active
                                  ? "Active"
                                  : "Inactive",
                                color: _vm.generalData.active
                                  ? _vm.COLORS.success
                                  : _vm.COLORS.danger,
                              },
                            }),
                          ],
                          1
                        ),
                        !_vm.isNonConnected
                          ? [
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Current mileage",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        "d-flex align-items-center mb-1",
                                        `emobg-color-${_vm.getIconColor(
                                          "mileage.value"
                                        )}`,
                                      ],
                                    },
                                    [
                                      _c("ui-icon", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          icon: _vm.ICONS.miles,
                                          color:
                                            _vm.getIconColor("mileage.value"),
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "emobg-body-2" },
                                        [
                                          _vm.get(
                                            _vm.generalData,
                                            "mileage.value"
                                          )
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatThousands(
                                                        _vm.generalData.mileage
                                                          .value
                                                      )
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.get(
                                                        _vm.generalData,
                                                        "mileage.unit"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FALLBACK_MESSAGE.noData
                                                  )
                                                ),
                                              ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Alerts",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _vm.alerts && _vm.alerts.length
                                    ? [
                                        _vm._l(
                                          _vm.alerts.slice(
                                            0,
                                            _vm.visibleAlertsCount
                                          ),
                                          function (alert, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass:
                                                  "emobg-body-1 py-1",
                                              },
                                              [
                                                _c("ui-badge", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.COLORS.warning,
                                                    solid: "",
                                                    circle: "",
                                                  },
                                                }),
                                                _vm._v(
                                                  " " + _vm._s(alert) + " "
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        _vm.alerts.length >
                                        _vm.visibleAlertsCount
                                          ? _c(
                                              "ui-button",
                                              {
                                                staticClass: "ml-n3",
                                                attrs: { face: _vm.FACES.text },
                                                on: {
                                                  clickbutton: function (
                                                    $event
                                                  ) {
                                                    _vm.isAlertListShown = true
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Show all (" +
                                                    _vm._s(_vm.alerts.length) +
                                                    ") "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.isFullListVisible
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "emobg-border-1 emobg-border-color-ground-light emobg-background-color-white DetailsView__alerts-list position-relative p-2 mt-n6 ml-n3 shadow-s",
                                              },
                                              [
                                                _c(
                                                  "ui-button",
                                                  {
                                                    staticClass: "float-right",
                                                    attrs: {
                                                      face: _vm.FACES.text,
                                                      square: "",
                                                    },
                                                    on: {
                                                      clickbutton: function (
                                                        $event
                                                      ) {
                                                        _vm.isAlertListShown = false
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("ui-icon", {
                                                      attrs: {
                                                        icon: _vm.ICONS.cross,
                                                        size: _vm.ICONS_SIZES
                                                          .small,
                                                        color:
                                                          _vm.GRAYSCALE.ground,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._l(
                                                  _vm.alerts,
                                                  function (alert, i) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "emobg-caption-1 py-1",
                                                      },
                                                      [
                                                        _c("ui-badge", {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            color:
                                                              _vm.COLORS
                                                                .warning,
                                                            solid: "",
                                                            circle: "",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(alert) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _c("ui-badge", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              color: _vm.GRAYSCALE.groundLight,
                                              solid: "",
                                              circle: "",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "emobg-color-ink-light",
                                            },
                                            [_vm._v(" No alerts ")]
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                2
                              ),
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Home location",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _vm.generalData.allocation
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-elipsis emobg-body-1",
                                        },
                                        [
                                          _vm.generalData.allocation.id &&
                                          _vm.generalData.allocation.name
                                            ? _c(
                                                "PermissionLink",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: _vm.fleet.locations
                                                        .detail.index,
                                                      params: {
                                                        locationUuid:
                                                          _vm.generalData
                                                            .allocation.uuid,
                                                      },
                                                    },
                                                    "link-permissions": [
                                                      _vm.CARSHARING_PERMISSIONS
                                                        .viewCarsharingLocations,
                                                    ],
                                                    "class-link":
                                                      "emobg-link-primary emobg-body-2",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.generalData
                                                          .allocation.name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.address ||
                                                  _vm.FALLBACK_MESSAGE.noData
                                              ) +
                                                ", " +
                                                _vm._s(
                                                  _vm.generalData.allocation
                                                    .neighborhood ||
                                                    _vm.FALLBACK_MESSAGE.noData
                                                )
                                            ),
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.generalData.allocation
                                                  .city ||
                                                  _vm.FALLBACK_MESSAGE.noData
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _c("ui-badge", {
                                        attrs: {
                                          text: _vm.FALLBACK_MESSAGE.noData,
                                          color: _vm.GRAYSCALE.ground,
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    !_vm.isNonConnected
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-md-auto d-flex flex-column align-items-center",
                          },
                          [_vm.isLoaded ? _c("VehicleLocation") : _vm._e()],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isLoaded && _vm.areTabsReady
        ? _c("PageTabs", { attrs: { tabs: _vm.tabs, "has-overview": "" } })
        : _vm._e(),
      _vm.isLoaded
        ? _c(
            "PageView",
            { attrs: { "is-tab-content": "" } },
            [
              _c(
                "Transition",
                { attrs: { name: "page" } },
                [_c("RouterView")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }