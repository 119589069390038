var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DetailsCasesView" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center mb-2",
        },
        [
          _c("h1", [_vm._v(" Cases ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "vehicle_cases_tab-create-button" },
              on: { clickbutton: _vm.redirectToCasesForm },
            },
            [_vm._v(" Create case ")]
          ),
        ],
        1
      ),
      _c("MuiAlgoliaList", {
        attrs: {
          "export-columns": _vm.contentCells,
          facets: _vm.caseEventsFacets,
          filters: _vm.caseEventsFilters,
          index: _vm.CASES_INDEX,
          "table-config": _vm.contentCells,
          "is-export-enabled": "",
          "is-sidebar-collapsed": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }