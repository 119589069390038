var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DetailsInteractionsView" },
    [
      _c("div", { staticClass: "d-flex row" }, [
        _vm.actionRoute === _vm.ACTIONS.INVALIDATE_KEYS &&
        _vm.isConnectedCarPlatform
          ? _c(
              "div",
              { staticClass: "d-flex col-xl-6 mb-4" },
              [
                _c("ui-card", { staticClass: "d-flex flex-column w-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between flex-grow-1 flex-column p-3",
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c(
                          "h2",
                          { staticClass: "mb-4 emobg-font-weight-bold" },
                          [_vm._v(" Invalidate virtual keys ")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-2 emobg-font-weight-bold emobg-color-black",
                          },
                          [_vm._v(" Invalidate virtual keys for this booking ")]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-end" },
                        [
                          _c(
                            "ui-button",
                            {
                              staticClass:
                                "height--initial wmin-initial py-2 mr-2",
                              attrs: {
                                loading: _vm.virtualKeysStatus.LOADING,
                                disabled: _vm.virtualKeysStatus.LOADING,
                                "data-test-id": "invalidate_virtual-keys",
                              },
                              on: {
                                clickbutton: function ($event) {
                                  _vm.patchInvalidateVirtualKey(
                                    _vm.get(_vm.booking, "uuid")
                                  )
                                },
                              },
                            },
                            [
                              _c("ui-icon", {
                                staticClass: "pr-2",
                                attrs: {
                                  icon: _vm.ICONS.keyfob,
                                  size: _vm.ICONS_SIZES.small,
                                },
                              }),
                              _vm._v(" Invalidate virtual keys "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.hasPermissions(
          _vm.CARSHARING_PERMISSIONS.viewCsVehicleInteractionsLock
        )
          ? _c(
              "div",
              { staticClass: "d-flex col-xl-6 mb-4" },
              [
                _c("ui-card", { staticClass: "d-flex flex-column w-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between flex-grow-1 flex-column p-3",
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c(
                          "h2",
                          { staticClass: "mb-4 emobg-font-weight-bold" },
                          [_vm._v(" Lock and unlock the vehicle doors ")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-2 emobg-font-weight-bold emobg-color-black",
                          },
                          [
                            _vm._v(
                              " These buttons will lock and unlock the vehicle doors. "
                            ),
                          ]
                        ),
                        _c("p", { staticClass: "mb-4" }, [
                          _vm._v(
                            " This function could be used when a customer has forgotten something in the vehicle once his booking is finalised. "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-end" },
                        [
                          _c(
                            "ui-button",
                            {
                              staticClass:
                                "height--initial wmin-initial py-2 mr-2",
                              attrs: {
                                loading: _vm.doorsUnlockStatus.LOADING,
                                disabled: _vm.doorsLockStatus.LOADING,
                              },
                              on: {
                                clickbutton: function ($event) {
                                  return _vm.getUnlockVehicle(_vm.vehicleUuid)
                                },
                              },
                            },
                            [
                              _c("ui-icon", {
                                staticClass: "pr-2",
                                attrs: {
                                  icon: _vm.ICONS.unlock,
                                  size: _vm.ICONS_SIZES.small,
                                },
                              }),
                              _vm._v(" Unlock doors "),
                            ],
                            1
                          ),
                          _c(
                            "ui-button",
                            {
                              staticClass: "height--initial wmin-initial py-2",
                              attrs: {
                                loading: _vm.doorsLockStatus.LOADING,
                                disabled: _vm.doorsUnlockStatus.LOADING,
                              },
                              on: {
                                clickbutton: function ($event) {
                                  return _vm.getLockVehicle(_vm.vehicleUuid)
                                },
                              },
                            },
                            [
                              _c("ui-icon", {
                                staticClass: "pr-2",
                                attrs: {
                                  icon: _vm.ICONS.lock,
                                  size: _vm.ICONS_SIZES.small,
                                },
                              }),
                              _vm._v(" Lock doors "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.hasPermissions(
          _vm.CARSHARING_PERMISSIONS.viewCsVehicleInteractionsLockMaster
        )
          ? _c(
              "div",
              { staticClass: "d-flex col-xl-6 mb-4" },
              [
                _c("ui-card", { staticClass: "d-flex flex-column w-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between flex-grow-1 flex-column p-3",
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c(
                          "h2",
                          { staticClass: "mb-4 emobg-font-weight-bold" },
                          [
                            _vm._v(
                              " Lock and unlock the vehicle doors and master menu "
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-2 emobg-font-weight-bold emobg-color-black",
                          },
                          [
                            _vm._v(
                              " These buttons will lock and unlock the vehicle doors and at the same time open and close the master menu in the vehicle terminal. "
                            ),
                          ]
                        ),
                        _c("p", { staticClass: "mb-4" }, [
                          _vm._v(
                            " These should be used only if an internal team member is in front of the vehicle and never to open the doors to a customer. "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-end" },
                        [
                          _c(
                            "ui-button",
                            {
                              staticClass:
                                "height--initial wmin-initial py-2 mr-2",
                              attrs: {
                                loading: _vm.menuOpenStatus.LOADING,
                                disabled: _vm.menuCloseStatus.LOADING,
                                color: _vm.COLORS.danger,
                              },
                              on: { clickbutton: _vm.openMasterMenu },
                            },
                            [
                              _c("ui-icon", {
                                staticClass: "pr-2",
                                attrs: {
                                  icon: _vm.ICONS.unlock,
                                  size: _vm.ICONS_SIZES.small,
                                },
                              }),
                              _vm._v(" Unlock & Open master menu "),
                            ],
                            1
                          ),
                          _c(
                            "ui-button",
                            {
                              staticClass: "height--initial wmin-initial py-2",
                              attrs: {
                                loading: _vm.menuCloseStatus.LOADING,
                                disabled: _vm.menuOpenStatus.LOADING,
                              },
                              on: {
                                clickbutton: function ($event) {
                                  return _vm.getCloseMasterMenu(_vm.vehicleUuid)
                                },
                              },
                            },
                            [
                              _c("ui-icon", {
                                staticClass: "pr-2",
                                attrs: {
                                  icon: _vm.ICONS.lock,
                                  size: _vm.ICONS_SIZES.small,
                                },
                              }),
                              _vm._v(" Lock and Close master menu "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.hasPermissions(
          _vm.CARSHARING_PERMISSIONS.viewCsVehicleInteractionsMobilise
        )
          ? _c(
              "div",
              { staticClass: "d-flex col-xl-6 mb-4" },
              [
                _c("ui-card", { staticClass: "d-flex flex-column w-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between flex-grow-1 flex-column p-3",
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c(
                          "h2",
                          { staticClass: "mb-4 emobg-font-weight-bold" },
                          [_vm._v(" Mobilise and immobilise the vehicle ")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-2 emobg-font-weight-bold emobg-color-black",
                          },
                          [
                            _vm._v(
                              " These buttons will either immobilise or mobilise the vehicle. "
                            ),
                          ]
                        ),
                        _c("p", { staticClass: "mb-4" }, [
                          _vm._v(
                            " When you immobilise a vehicle the engine cannot be started again once turned off. For the user to be able to turn on the vehicle again it will need to be mobilised. "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mt-4 d-flex justify-content-end" },
                        [
                          _c(
                            "ui-button",
                            {
                              staticClass:
                                "height--initial wmin-initial py-2 mr-2",
                              attrs: {
                                loading: _vm.immobilizeStatus.LOADING,
                                disabled: _vm.mobilizeStatus.LOADING,
                                color: _vm.COLORS.danger,
                              },
                              on: {
                                clickbutton: function ($event) {
                                  return _vm.immobilise()
                                },
                              },
                            },
                            [_vm._v(" Immobilise ")]
                          ),
                          _c(
                            "ui-button",
                            {
                              staticClass: "height--initial wmin-initial py-2",
                              attrs: {
                                loading: _vm.mobilizeStatus.LOADING,
                                disabled: _vm.immobilizeStatus.LOADING,
                              },
                              on: {
                                clickbutton: function ($event) {
                                  return _vm.getMobilizeVehicle(_vm.vehicleUuid)
                                },
                              },
                            },
                            [_vm._v(" Mobilise ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.isModalVisible
        ? _c(
            "GenericModalComponent",
            _vm._g(
              {
                attrs: { header: null, "backdrop-dismiss": "" },
                on: {
                  "close-modal": _vm.closeModal,
                  "modal-closed": _vm.closeModal,
                },
              },
              _vm.$listeners
            ),
            [
              _c("template", { slot: "body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large pb-3 emobg-color-ink",
                  },
                  [_vm._v(" " + _vm._s(_vm.modalHeaderTitle) + " ")]
                ),
                _vm.action === _vm.ACTIONS.MASTER_MENU
                  ? _c("div", { staticClass: "p-0" }, [
                      _c("p", { staticClass: "pb-4" }, [
                        _vm._v(
                          " Note that this action will only work if the card reader is active. Also, be aware that this action will terminate any started booking. "
                        ),
                      ]),
                      _c("p", { staticClass: "pb-1" }, [
                        _vm._v(" PIN code: 7037 "),
                      ]),
                    ])
                  : _vm._e(),
                _vm.action === _vm.ACTIONS.IMMOBILISE
                  ? _c("div", [
                      _c("p", { staticClass: "pb-1" }, [
                        _vm._v(
                          " If you immobilise the vehicle, the driver won't be able to turn on the engine again once turned off. "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-content-center justify-content-end w-100",
                  },
                  [
                    _c(
                      "ui-button",
                      {
                        staticClass: "height--initial wmin-initial py-2 mr-2",
                        attrs: {
                          color: _vm.GRAYSCALE.inkLight,
                          face: _vm.FACES.text,
                        },
                        on: { clickbutton: _vm.closeModal },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "ui-button",
                      {
                        staticClass: "height--initial wmin-initial py-2",
                        attrs: { color: _vm.COLORS.danger },
                        on: { clickbutton: _vm.confirmAction },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.action === _vm.ACTIONS.IMMOBILISE
                                ? "Immobilise"
                                : "Confirm"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }