import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { ConnectivityStatusComponent } from '@/components';

export function CONNECTIVITY_SCHEMA({ timezone }) {
  return [
    {
      header: 'First occurrence',
      template: ({ firstOccurrence }) => (
        firstOccurrence ? formatUtc(firstOccurrence, DATE_FORMAT.defaultExtended, timezone) : FALLBACK_MESSAGE.dash
      ),
    },
    {
      header: 'Last occurrence',
      template: ({ lastOccurrence }) => (
        lastOccurrence ? formatUtc(lastOccurrence, DATE_FORMAT.defaultExtended, timezone) : FALLBACK_MESSAGE.dash
      ),
    },
    {
      header: 'Connectivity',
      template: ({ value }) => sentenceCase(value),
      component: ConnectivityStatusComponent,
      props: ({ value: status }) => ({
        showIcon: false,
        status,
      }),
    },
  ];
}

