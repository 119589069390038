var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ChargingStatusLogsView" },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Charging status ")]),
      _c("FilterTableComponent", {
        attrs: {
          schema: _vm.chargingStatusSchema,
          "export-schema": _vm.chargingStatusExportSchema,
          data: _vm.chargingStatusLogData,
          "is-loading": _vm.chargingStatusLogLoading,
          exportable: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }