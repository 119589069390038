<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    damage: {
      type: Object,
      required: true,
    },
    onDeleteDamage: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.vehicleDamages, {
      deleteDamageStatus: state => state.deleteDamage.STATUS,
    }),
    text() {
      return `#${this.damage.id}<p class="pt-2">It cannot be undone.</p>`;
    },
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.vehicleDamages, [
      'deleteVehicleDamage',
    ]),
    get,
    async removeDamage() {
      await this.deleteVehicleDamage({ damageUuid: this.damage.uuid });
      if (!this.deleteDamageStatus.ERROR) {
        this.$notify({
          message: 'Damage successfully <span class="emobg-font-weight-semibold">deleted</span>',
          textAction: '',
        });
        this.onDeleteDamage();
      }
      this.$emit('closeModal');
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteDocumentModal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        :text="text"
        title="Delete the damage?"
      />
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <DeleteButton
        :loading="deleteDamageStatus.LOADING"
        data-test-id="delete-button"
        @click="removeDamage"
      />
    </template>
  </GenericModalComponent>
</template>
