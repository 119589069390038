var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "EditDescriptionComponent row" }, [
    _c(
      "div",
      { staticClass: "col-12 col-md-6" },
      [
        _c(
          "ui-alert",
          {
            staticClass: "w-100 pb-2",
            attrs: { color: _vm.COLORS.warning, icon: _vm.ICONS.alertFull },
          },
          [
            _vm._v(
              " This description is visible to users in apps and on the website "
            ),
          ]
        ),
        _c("MarkdownComponent", {
          staticClass: "mb-4 mt-3",
          attrs: { content: _vm.internalDescriptions, language: _vm.language },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-12 col-md-6" },
      [
        _c("TranslatableFieldComponent", {
          on: {
            "update:language": (selectedLanguage) =>
              (_vm.language = selectedLanguage),
          },
          model: {
            value: _vm.internalDescriptions,
            callback: function ($$v) {
              _vm.internalDescriptions = $$v
            },
            expression: "internalDescriptions",
          },
        }),
        _c("MarkdownInformation"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }