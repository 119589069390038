<script>
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  DATE_FORMAT,
  DELAY,
  formatUtc,
  TIME_ZONE,
} from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { GoogleMapZonesComponent } from '@/components';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { CARSHARING_PERMISSIONS } from '../../../const/permissions';
import VehicleLocationRequestModal from './VehicleLocationRequestModal/VehicleLocationRequestModal';
import { VehicleLocationExceptionKeys } from './const/vehicleLocationExceptions.const';

export default {
  name: 'VehicleLocation',
  components: {
    GoogleMapZonesComponent,
    VehicleLocationRequestModal,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      gpsRequestError: state => state.position.error,
      isGpsRequestError: state => state.position.STATUS.ERROR,
      isGpsRequestLoading: state => state.position.STATUS.LOADING,
      vehicle: state => state.general.data,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'hasPermissions',
    ]),
    allocationZone() {
      const zones = get(this, 'vehicle.allocation.coordinates');

      const { type, data: polygon } = zones || {};
      return !isEmpty(zones) ? { zone: { type, polygon } } : {};
    },
    mapMarkers() {
      const markers = [...this.getAllocation()];
      if (this.hasPermissions(CARSHARING_PERMISSIONS.viewCsVehicleCurrentLocation)) {
        markers.push(...this.getLocation());
      }
      return markers;
    },
    isVehicleShown() {
      return !!find(this.mapMarkers, { label: 'Vehicle location' });
    },
    tooltipRequestButton() {
      return this.isVehicleShown
        ? 'You can Request vehicle location more than once.'
        : 'Vehicle location during the booking is sensitive information. To see the location, request it below.';
    },
  },
  created() {
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    get,
    formatUtc,
    ...mapMutations(DOMAINS_MODEL.carsharing.vehicle.details.information, ['setVehicleLocationAsInBooking']),
    ...mapActions(DOMAINS_MODEL.carsharing.vehicle.details.information, ['getGpsPosition']),
    getAllocation() {
      const { lat: latitude, lng: longitude } = get(this, 'vehicle.allocation', {});

      const allocation = [{
        type: 'coordinates',
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        icon: { url: '/carsharing/station.svg' },
        label: 'Home location',
        uuid: 'station',
      }];
      const isValid = !isNil(latitude) && !isNil(longitude);

      return isValid
        ? allocation
        : [];
    },
    getLocation() {
      const { lat, lng } = get(this, 'vehicle.location', {});
      const hasValidLocation = lat && lng;
      if (!hasValidLocation) {
        return [];
      }

      const latitude = parseFloat(lat);
      const longitude = parseFloat(lng);
      const icon = { url: '/carsharing/vehicle.svg' };

      const location = [{
        type: 'coordinates',
        latitude,
        longitude,
        icon,
        label: 'Vehicle location',
        uuid: 'vehicle',
      }];
      const isValid = !isNil(latitude) && !isNil(longitude);

      return isValid
        ? location
        : [];
    },
    async getPosition() {
      const previousInBooking = get(this.vehicle, 'location.in_booking');
      await this.getGpsPosition({ vehicleUuid: this.vehicle.uuid });

      if (this.isGpsRequestError) {
        const isNoReasonKey = get(this.gpsRequestError, 'key') === VehicleLocationExceptionKeys.noReasonGiven;
        if (isNoReasonKey) {
          if (!previousInBooking) {
            this.setVehicleLocationAsInBooking();
          }
          this.isModalOpen = true;
        } else {
          this.$throwError(this.gpsRequestError);
        }
        return;
      }

      const actualInBooking = get(this.vehicle, 'location.in_booking');

      if (!actualInBooking && previousInBooking) {
        this.$notify({
          message: 'Not required. Booking finished.',
          delay: DELAY.extraLong,
          textAction: '',
          type: NOTIFICATION_TYPES.info,
        });
      }
    },
  },
};
</script>

<template>
  <div class="VehicleLocation emobg-border-radius-small emobg-border-color-ground-light emobg-border-1">
    <GoogleMapZonesComponent
      :map-type-control="false"
      :zones="allocationZone"
      :marker-sources="mapMarkers"
      is-static
      street-view-control
      full-screen-control
    />
    <div
      :class="[
        'd-flex justify-content-center align-items-center p-3',
        {
          'flex-column': get(vehicle, 'location.in_booking')
        }
      ]"
    >
      <div
        v-if="get(vehicle, 'location.timestamp')"
        class="emobg-caption-1 emobg-color-ink-light mr-2"
      >
        Updated: {{ formatUtc(vehicle.location.timestamp, DATE_FORMAT.defaultExtended, operatorTimezone) }}
      </div>
      <template v-if="hasPermissions(CARSHARING_PERMISSIONS.viewCsVehicleCurrentLocation)">
        <div
          v-if="get(vehicle, 'location.in_booking')"
          class="mt-2"
        >
          <p class="d-flex align-items-center">
            Active booking.
            <span
              v-if="isVehicleShown"
              class="emobg-color-success"
            >
              Vehicle shown
            </span>
            <template v-else>
              Vehicle hidden
            </template>
            <ui-tooltip
              :tooltip="tooltipRequestButton"
              class="ml-1"
            >
              <ui-icon
                :size="ICONS_SIZES.small"
                :icon="ICONS.infoFull"
                class="emobg-color-ink-light emobg-color-ink-hover"
              />
            </ui-tooltip>
          </p>
          <ui-button
            :disabled="isGpsRequestLoading"
            :face="FACES.outline"
            class="mt-1"
            data-test-id="vehicle_location-request-button"
            @clickbutton="getPosition"
          >
            Request vehicle location
          </ui-button>
        </div>
        <ui-button
          v-else
          :face="FACES.text"
          :loading="isGpsRequestLoading"
          class="d-inline-block pl-0 pr-0 min-width--none"
          data-test-id="vehicle_location-request-button"
          @clickbutton="getPosition"
        >
          <div class="d-flex align-items-center">
            <ui-icon
              :icon="ICONS.refresh"
              :size="ICONS_SIZES.medium"
              class="mr-2"
            />
            <span class="emobg-caption-1">Refresh</span>
          </div>
        </ui-button>
      </template>
    </div>
    <VehicleLocationRequestModal
      v-if="isModalOpen"
      @closeModal="isModalOpen = false"
    />
  </div>
</template>

<style lang="scss">
.VehicleLocation {
  width: 302px;
}
</style>
