<script>
import find from 'lodash/find';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import inRange from 'lodash/inRange';
import { SIZES } from '@emobg/vue-base';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';

export default {
  name: 'BatteryLevel',
  props: {
    batteryLevel: {
      type: [Number, null],
      default: null,
    },
    dangerLevel: {
      type: Number,
      default: 0,
    },
    iconSize: {
      type: String,
      default: get(SIZES, 'large'),
    },
    textClass: {
      type: String,
      default: 'ml-2',
    },
    fallbackMessage: {
      type: String,
      default: FALLBACK_MESSAGE.noData,
    },
  },
  computed: {
    iconAccordingToLevel() {
      return [
        {
          icon: this.ICONS.battery,
          range: [0, 1],
        },
        {
          icon: this.ICONS.batteryCritical,
          range: [1, this.dangerLevel + 1],
        },
        {
          icon: this.ICONS.batteryLow,
          range: [this.dangerLevel + 1, 75],
        },
        {
          icon: this.ICONS.batteryModerate,
          range: [75, 100],
        },
        {
          icon: this.ICONS.batteryFull,
          range: [100, 101],
        },
      ];
    },
    iconBatteryLevel() {
      // inRange accepts null as 0, so we convert null to undefined to get "false" correctly.
      const level = isNull(this.batteryLevel) ? undefined : this.batteryLevel;
      const iconLevel = find(this.iconAccordingToLevel, iconLevelObject => inRange(level, ...iconLevelObject.range));
      return iconLevel
        ? iconLevel.icon
        : this.ICONS.batteryEmpty;
    },
  },
};
</script>

<template>
  <div
    class="BatteryLevel d-inline-flex align-items-center"
    data-test-id="battery_level"
  >
    <ui-icon
      :icon="iconBatteryLevel"
      :size="iconSize"
      :color="iconBatteryLevel === ICONS.battery ? COLORS.danger : COLORS.ink"
      data-test-id="icon"
    />
    <span :class="textClass">
      {{ iconBatteryLevel !== ICONS.batteryEmpty ? `${batteryLevel}%` : fallbackMessage }}
    </span>
  </div>
</template>
