var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "EditFinancialInfoComponent row",
      attrs: { "data-test-id": "financial_info-form" },
    },
    [
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("MuiSelect", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isRequired: true,
                },
                expression: "{\n        isRequired: true,\n      }",
              },
            ],
            staticClass: "w-100",
            attrs: {
              options: _vm.map(_vm.FINANCING_MODE, (status) => ({
                label: _vm.sentenceCase(status),
                value: status,
              })),
              placeholder: "Financing mode",
              label: "Financing mode*",
              name: "financing_mode",
              "data-test-id": "financing_mode-select",
            },
            model: {
              value: _vm.payload.financing_mode,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "financing_mode", $$v)
              },
              expression: "payload.financing_mode",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("MuiAlgoliaSelect", {
            staticClass: "w-100",
            attrs: {
              index: _vm.ALGOLIA_INDEXES.companies,
              title: (result) => result.commercial_name,
              "on-clear": () => null,
              "show-clear": "",
              "path-value": "uuid",
              placeholder: "Select",
              label: "Company",
              name: "commercial_name",
              "data-test-id": "company-select",
            },
            model: {
              value: _vm.payload.company_uuid,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "company_uuid", $$v)
              },
              expression: "payload.company_uuid",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("MuiAlgoliaSelect", {
            staticClass: "w-100",
            attrs: {
              index: _vm.ALGOLIA_INDEXES.companies,
              title: (result) => result.commercial_name,
              "on-clear": () => null,
              "show-clear": "",
              "path-value": "uuid",
              label: "Leaser company",
              placeholder: "Leaser company",
              name: "leaser_company",
              "data-test-id": "leaser_company-select",
            },
            model: {
              value: _vm.payload.leaser_company_uuid,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "leaser_company_uuid", $$v)
              },
              expression: "payload.leaser_company_uuid",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("ui-datetimepicker", {
            staticClass: "w-100",
            attrs: {
              size: _vm.SIZES.small,
              "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
              label: "Order date",
              name: "order_date",
              "data-test-id": "order_date-input",
              skiptime: "",
            },
            domProps: { date: _vm.payload.order_date || undefined },
            on: {
              datechanged: ({ detail }) =>
                (_vm.payload.order_date = detail
                  ? detail.format(_vm.DATE_FORMAT.dob)
                  : undefined),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("ui-datetimepicker", {
            staticClass: "w-100",
            attrs: {
              size: _vm.SIZES.small,
              "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
              label: "Delivery date",
              name: "delivery_date",
              "data-test-id": "delivery_date-input",
              skiptime: "",
            },
            domProps: { date: _vm.payload.delivery_date || undefined },
            on: {
              datechanged: ({ detail }) =>
                (_vm.payload.delivery_date = detail
                  ? detail.format(_vm.DATE_FORMAT.dob)
                  : undefined),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("ui-datetimepicker", {
            staticClass: "w-100",
            attrs: {
              size: _vm.SIZES.small,
              "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
              label: "Sale date",
              name: "sale_date",
              "data-test-id": "sale_date-input",
              skiptime: "",
            },
            domProps: { date: _vm.payload.sale_date || undefined },
            on: {
              datechanged: ({ detail }) =>
                (_vm.payload.sale_date = detail
                  ? detail.format(_vm.DATE_FORMAT.dob)
                  : undefined),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("MuiInputText", {
            staticClass: "w-100",
            attrs: {
              placeholder: "Contract number",
              label: "Contract number",
              name: "contract_number",
            },
            model: {
              value: _vm.payload.contract_number,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "contract_number", $$v)
              },
              expression: "payload.contract_number",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("ui-toggle", {
            attrs: {
              value: _vm.payload.is_flexible_contract,
              label: "Is contract flexible",
              name: "flexible_contract",
              "data-test-id": "flexible_contract-toggle",
            },
            on: {
              changevalue: ({ detail }) =>
                (_vm.payload.is_flexible_contract = detail),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("ui-toggle", {
            attrs: {
              value: _vm.payload.is_bonus_managed_by_renter,
              label: "Bonus managed by renter",
              name: "bonus_managed_by_renter",
              "data-test-id": "bonus_managed_by_renter-toggle",
            },
            on: {
              changevalue: ({ detail }) =>
                (_vm.payload.is_bonus_managed_by_renter = detail),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("ui-datetimepicker", {
            staticClass: "w-100",
            attrs: {
              size: _vm.SIZES.small,
              "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
              label: "First registration date",
              name: "first_registration_date",
              "data-test-id": "first_registration_date-input",
              skiptime: "",
            },
            domProps: {
              date: _vm.payload.first_registration_date || undefined,
            },
            on: {
              datechanged: ({ detail }) =>
                (_vm.payload.first_registration_date = detail
                  ? detail.format(_vm.DATE_FORMAT.dob)
                  : undefined),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6" },
        [
          _c("MuiInputText", {
            staticClass: "w-100 mb-3",
            attrs: {
              placeholder: "Contracted mileage",
              label: "Contracted mileage",
              name: "contracted_mileage",
              "data-test-id": "contracted_mileage-input",
            },
            model: {
              value: _vm.payload.contracted_mileage,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "contracted_mileage", $$v)
              },
              expression: "payload.contracted_mileage",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("MuiInputText", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isPattern: _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                },
                expression:
                  "{\n        isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n      }",
              },
            ],
            staticClass: "w-100",
            attrs: {
              placeholder: "Warranty duration (Months)",
              label: "Warranty duration (Months)",
              name: "months_warranty_duration",
              "data-test-id": "month_warranty_duration-input",
            },
            model: {
              value: _vm.payload.months_warranty_duration,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "months_warranty_duration", $$v)
              },
              expression: "payload.months_warranty_duration",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("MuiInputText", {
            staticClass: "w-100",
            attrs: {
              placeholder: "Insurance carrier",
              label: "Insurance carrier",
              name: "insurance_carrier",
              "data-test-id": "insurance_carrier-input",
            },
            model: {
              value: _vm.payload.insurance_carrier,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "insurance_carrier", $$v)
              },
              expression: "payload.insurance_carrier",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("MuiInputText", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isPattern: _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
                },
                expression:
                  "{\n        isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n      }",
              },
            ],
            staticClass: "w-100",
            attrs: {
              placeholder: "First rent amount without VAT",
              label: "First rent amount without VAT",
              name: "first_rent_amount_without_vat",
              "data-test-id": "first_rent_amount_without_vat-input",
            },
            on: {
              blur: (value) =>
                (_vm.payload.first_rent_amount_without_vat =
                  _vm.toDecimalFormat(value, { dotFormat: true })),
            },
            model: {
              value: _vm.payload.first_rent_amount_without_vat,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "first_rent_amount_without_vat", $$v)
              },
              expression: "payload.first_rent_amount_without_vat",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("MuiInputText", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isPattern: _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
                },
                expression:
                  "{\n        isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n      }",
              },
            ],
            staticClass: "w-100",
            attrs: {
              placeholder: "Rent amount without VAT",
              label: "Rent amount without VAT",
              name: "rent_amount_without_vat",
              "data-test-id": "rent_amount_without_vat-input",
            },
            on: {
              blur: (value) =>
                (_vm.payload.rent_amount_without_vat = _vm.toDecimalFormat(
                  value,
                  { dotFormat: true }
                )),
            },
            model: {
              value: _vm.payload.rent_amount_without_vat,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "rent_amount_without_vat", $$v)
              },
              expression: "payload.rent_amount_without_vat",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("MuiInputText", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isPattern: _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
                },
                expression:
                  "{\n        isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n      }",
              },
            ],
            staticClass: "w-100",
            attrs: {
              placeholder: "Bonus amount",
              label: "Bonus amount",
              name: "bonus_amount",
              "data-test-id": "bonus_amount-input",
            },
            on: {
              blur: (value) =>
                (_vm.payload.bonus_amount = _vm.toDecimalFormat(value, {
                  dotFormat: true,
                })),
            },
            model: {
              value: _vm.payload.bonus_amount,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "bonus_amount", $$v)
              },
              expression: "payload.bonus_amount",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("MuiInputText", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  isPattern: _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
                },
                expression:
                  "{\n        isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n      }",
              },
            ],
            staticClass: "w-100",
            attrs: {
              placeholder: "Extra mileage cost",
              label: "Extra mileage cost",
              name: "extra_mileage_cost",
              "data-test-id": "extra_mileage_cost-input",
            },
            on: {
              blur: (value) =>
                (_vm.payload.extra_mileage_cost = _vm.toDecimalFormat(value, {
                  dotFormat: true,
                })),
            },
            model: {
              value: _vm.payload.extra_mileage_cost,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "extra_mileage_cost", $$v)
              },
              expression: "payload.extra_mileage_cost",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("MuiSelect", {
            staticClass: "w-100",
            attrs: {
              options: _vm.map(_vm.JURIDIC_STATUS, (status) => ({
                label: _vm.sentenceCase(status),
                value: status,
              })),
              "on-clear": () => null,
              "show-clear": "",
              placeholder: "Juridic status",
              label: "Juridic status",
              name: "type",
              "data-test-id": "juridict_status-select",
            },
            model: {
              value: _vm.payload.juridic_status,
              callback: function ($$v) {
                _vm.$set(_vm.payload, "juridic_status", $$v)
              },
              expression: "payload.juridic_status",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("ui-datetimepicker", {
            staticClass: "w-100",
            attrs: {
              size: _vm.SIZES.small,
              "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
              label: "Juridic start date",
              name: "juridic_start_date",
              "data-test-id": "juridic_start_date-input",
              skiptime: "",
            },
            domProps: { date: _vm.payload.juridic_start_date || undefined },
            on: {
              datechanged: ({ detail }) =>
                (_vm.payload.juridic_start_date = detail
                  ? detail.format(_vm.DATE_FORMAT.dob)
                  : undefined),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6 mb-4" },
        [
          _c("ui-datetimepicker", {
            staticClass: "w-100",
            attrs: {
              size: _vm.SIZES.small,
              "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
              label: "Juridic end date",
              name: "juridic_end_date",
              "data-test-id": "juridic_end_date-input",
              skiptime: "",
            },
            domProps: { date: _vm.payload.juridic_end_date || undefined },
            on: {
              datechanged: ({ detail }) =>
                (_vm.payload.juridic_end_date = detail
                  ? detail.format(_vm.DATE_FORMAT.dob)
                  : undefined),
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }