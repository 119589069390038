import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import fleetRoutes from '../../../router/FleetRouterMap';

export const VEHICLE_TABS = [
  {
    label: {
      title: 'Setup',
    },
    url: fleetRoutes.vehicles.detail.setup,
    permissions: [CARSHARING_PERMISSIONS.viewCsVehicleSetup],
  },
  {
    label: {
      title: 'Comments',
    },
    url: fleetRoutes.vehicles.detail.comments,
    permissions: [CARSHARING_PERMISSIONS.viewCsVehicleComments],
  },
  {
    label: {
      title: 'Cases',
    },
    url: fleetRoutes.vehicles.detail.cases,
    permissions: [CRM_PERMISSIONS.viewCrmCases],
  },
  {
    label: {
      title: 'Damages',
    },
    url: fleetRoutes.vehicles.detail.damages,
    permissions: [CARSHARING_PERMISSIONS.viewCarsharingDamages],
  },
  {
    label: {
      title: 'Bookings',
    },
    url: fleetRoutes.vehicles.detail.bookings,
    permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
  },
  {
    label: {
      title: 'Logs',
    },
    url: fleetRoutes.vehicles.detail.logs.index,
    permissions: [CARSHARING_PERMISSIONS.viewCsVehicleLogs],
  },
  {
    label: {
      title: 'Interactions',
    },
    url: fleetRoutes.vehicles.detail.interactions,
    permissions: [CARSHARING_PERMISSIONS.viewCsVehicleInteractions],
  },
];
