<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import mapKeys from 'lodash/mapKeys';
import values from 'lodash/values';
import { MarkdownComponent, MarkdownInformation, TranslatableFieldComponent } from '@/components';
import snakeCase from 'lodash/snakeCase';

export default {
  name: 'EditDescriptionComponent',
  components: {
    MarkdownInformation,
    MarkdownComponent,
    TranslatableFieldComponent,
  },
  props: {
    descriptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      originalDescriptions: null,
      internalDescriptions: { ...this.descriptions },
      language: null,
    };
  },
  computed: {
    hasSameValues() {
      return isEqual(this.internalDescriptions, this.originalDescriptions);
    },
  },
  watch: {
    hasSameValues(value) {
      this.$emit('update:payload', value);
    },
    internalDescriptions: {
      handler() {
        this.$emit(
          'update',
          mapKeys(
            this.internalDescriptions,
            (_nameKey, valueKey) => {
              const codeLanguageArray = snakeCase(valueKey).split('_');
              return `description_${codeLanguageArray[0]}_${codeLanguageArray[1].toUpperCase()}`;
            },
          ),
        );
      },
      deep: true,
    },
  },
  created() {
    this.originalDescriptions = cloneDeep(this.internalDescriptions);
  },
  methods: {
    values,
  },
};
</script>

<template>
  <div class="EditDescriptionComponent row">
    <div class="col-12 col-md-6">
      <ui-alert
        :color="COLORS.warning"
        :icon="ICONS.alertFull"
        class="w-100 pb-2"
      >
        This description is visible to users in apps and on the website
      </ui-alert>
      <MarkdownComponent
        :content="internalDescriptions"
        :language="language"
        class="mb-4 mt-3"
      />
    </div>
    <div class="col-12 col-md-6">
      <TranslatableFieldComponent
        v-model="internalDescriptions"
        @update:language="selectedLanguage => language = selectedLanguage"
      />
      <MarkdownInformation />
    </div>
  </div>
</template>
