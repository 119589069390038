import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  TIME_ZONE,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import crm from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { PermissionLink } from '@/components';

export function contentCells(timezone) {
  return [
    {
      attributeName: 'date',
      title: 'Date',
      displayPriority: 1,
      transformValue: value => (value ? formatUtc(value, DATE_FORMAT.defaultExtended, timezone) : FALLBACK_MESSAGE.dash),
    },
    {
      attributeName: 'user',
      title: 'User',
      displayPriority: 2,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: ({ user }) => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: crm.users.detail.index,
          params: {
            userUuid: user.uuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: `${user.first_name} ${user.last_name}`,
      }),
    },
    {
      attributeName: 'reason',
      displayPriority: 3,
      title: 'Reason',
      transformValue: sentenceCase,
    },
  ];
}

export const locationRequestsFacets = timezone => [
  {
    type: 'rangedatetimepicker',
    props: {
      title: 'Date',
      attributeName: 'date',
      labels: {
        from: 'From',
        to: 'To',
        removeButton: 'Clear dates',
      },
      timezone: timezone || TIME_ZONE.default,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Reason',
      attributeName: 'reason',
      transformValue: sentenceCase,
    },
  },
];

