var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoaded
    ? _c(
        "div",
        { staticClass: "DetailsLogsView" },
        [
          _vm.areTabsReady
            ? _c("PageTabs", {
                attrs: { tabs: _vm.tabs, "are-inner-tabs": "" },
              })
            : _vm._e(),
          _c(
            "PageView",
            { attrs: { "is-inner-tab-content": "" } },
            [_c("RouterView")],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }