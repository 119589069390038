import range from 'lodash/range';

export const FLEETS = {
  open: 'open',
  dedicated: 'dedicated',
};

export const SEATS = {
  min: 2,
  max: 9,
};

export const SEATS_OPTIONS = range(SEATS.min, SEATS.max + 1);
