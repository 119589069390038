<script>
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import toUpper from 'lodash/toUpper';
import values from 'lodash/values';

import { mapGetters } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiSelect,
  Validate,
} from '@emobg/motion-ui/v1';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { PATTERN_INPUT_VALIDATIONS, toNumberUtil as toNumber } from '@/utils';
import { CHASSIS_VALIDATION } from '../../../const/validations';
import { SEATS_OPTIONS } from '../../../const/vehicles.const';
import editMixin from './editMixin';
import {
  BRANDING,
  SEATS_COLORS,
  TRANSMISSION_TYPES,
  VEHICLE_COLORS,
} from './const/editVehicleComponents';

export default {
  name: 'EditGeneralInfoComponent',
  directives: {
    Validate,
  },
  components: {
    MuiAlgoliaSelect,
    MuiInputText,
    MuiSelect,
  },
  mixins: [editMixin],
  props: {
    info: {
      type: [Array, Object],
      default: () => ({}),
    },
  },
  data() {
    return {
      originalPayload: null,
      payload: {
        license_plate: get(this, 'info.license_plate'),
        vehicle_model_uuid: get(this, 'info.model.uuid'),
        chassis_number: get(this, 'info.chassis_number'),
        cs_operator_uuid: get(this, 'info.cs_operator_uuid'),
        vehicle_category_uuid: get(this, 'info.category.uuid'),
        branding: get(this, 'info.branding'),
        color: get(this, 'info.color'),
        seats: get(this, 'info.seats'),
        seats_color: get(this, 'info.seats_color'),
        transmission: get(this, 'info.transmission'),
        engine: get(this, 'info.engine'),
        horse_power: get(this, 'info.horse_power'),
        fuel_consumption: get(this, 'info.fuel_consumption') || '',
        co2_emission: get(this, 'info.co2_emission') || '',
      },
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    hasSameValues() {
      return isEqual(this.payload, this.originalPayload);
    },
    hasChassisNumberChanged() {
      return !isEqual(this.originalPayload.chassis_number, this.payload.chassis_number);
    },
  },
  watch: {
    hasSameValues(value) {
      this.$emit('update:payload', value);
    },
  },
  created() {
    this.VEHICLE_SEATS_OPTIONS = SEATS_OPTIONS;
    this.BRANDING = BRANDING;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.SEATS_COLORS = SEATS_COLORS;
    this.TRANSMISSION_TYPES = TRANSMISSION_TYPES;
    this.VEHICLE_COLORS = VEHICLE_COLORS;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.CHASSIS_VALIDATION = CHASSIS_VALIDATION;
    this.originalPayload = cloneDeep(this.payload);
  },
  methods: {
    toNumber,
    values,
    transformChassisToUpperCase(value) {
      this.payload.chassis_number = toUpper(value);
    },
  },
};
</script>

<template>
  <div class="EditGeneralInfoComponent row">
    <div class="col-6">
      <div class="mb-4">
        <MuiInputText
          v-model="payload.license_plate"
          v-validate="{
            isRequired: true,
          }"
          label="License plate*"
          name="licensePlate"
          placeholder="Enter plate"
          data-test-id="license_plate-input"
        />
      </div>
    </div>
    <div class="col-6">
      <div class="mb-4">
        <MuiAlgoliaSelect
          v-model="payload.vehicle_model_uuid"
          v-validate="{
            isRequired: true,
          }"
          :index="ALGOLIA_INDEXES.vehicleModels"
          :title="model => `${model.brand.name} ${model.name} (${model.fuel_type})`"
          class="w-100 text-capitalize"
          path-value="uuid"
          label="Model*"
          name="model"
          placeholder="Select"
          data-test-id="model-select"
        />
      </div>
    </div>
    <div class="col-6">
      <div class="mb-4">
        <MuiInputText
          v-model="payload.chassis_number"
          v-validate="CHASSIS_VALIDATION"
          maxlength="17"
          label="Chassis number (VIN)*"
          name="chassisNumber"
          placeholder="Enter number value"
          data-test-id="chassis_number-input"
          @input="transformChassisToUpperCase"
        />
        <ui-alert
          v-if="hasChassisNumberChanged"
          :color="COLORS.primary"
          :icon="ICONS.infoFull"
          class="mt-2 d-block"
        >
          <p class="emobg-body-1">
            Make sure this <span class="emobg-body-2">chassis number</span> is right, to
            ensure connectivity and correct vehicle management
          </p>
        </ui-alert>
      </div>
    </div>
    <div class="col-6">
      <div class="mb-4">
        <MuiAlgoliaSelect
          v-model="payload.cs_operator_uuid"
          v-validate="{
            isRequired: true,
          }"
          :index="ALGOLIA_INDEXES.csOperators"
          :title="operator => operator.name"
          :filters="getOperatorFilter({ attribute: 'id' })"
          class="w-100"
          path-value="uuid"
          label="CS Operator*"
          name="operator"
          placeholder="Select the CS Operator"
          data-test-id="operator-select"
        />
      </div>
    </div>

    <div class="col-6">
      <div class="mb-4">
        <MuiAlgoliaSelect
          v-model="payload.vehicle_category_uuid"
          v-validate="{
            isRequired: true,
          }"
          :index="ALGOLIA_INDEXES.vehicleCategories"
          :filters="`cs_operator_uuid:${payload.cs_operator_uuid} AND active: 1`"
          :title="result => result.name"
          class="w-100"
          path-value="uuid"
          label="Vehicle category*"
          name="category"
          placeholder="Select category"
          data-test-id="category-select"
        />
      </div>
    </div>
    <div class="col-6">
      <div class="mb-4">
        <MuiSelect
          v-model="payload.branding"
          v-validate="{
            isRequired: true,
          }"
          :options="values(BRANDING)"
          class="w-100 text-capitalize"
          label="Branding*"
          placeholder="Select"
          name="branding"
          data-test-id="branding-select"
        />
      </div>
    </div>

    <div class="col-6">
      <div class="mb-4">
        <MuiSelect
          v-model="payload.color"
          v-validate="{
            isRequired: true,
          }"
          :options="values(VEHICLE_COLORS)"
          class="w-100 text-capitalize"
          label="Colour*"
          placeholder="Select"
          name="color"
          data-test-id="color-select"
        />
      </div>
    </div>

    <div class="col-6">
      <div class="mb-4">
        <MuiSelect
          v-model="payload.seats"
          v-validate="{
            isRequired: true,
          }"
          :options="VEHICLE_SEATS_OPTIONS"
          class="w-100"
          label="Number of seats*"
          name="seats"
          placeholder="Select a number of seats"
          data-test-id="seats-input"
        />
      </div>
    </div>

    <div class="col-6">
      <div class="mb-4">
        <MuiSelect
          v-model="payload.seats_color"
          v-validate="{
            isRequired: true,
          }"
          :options="values(SEATS_COLORS)"
          class="w-100 text-capitalize"
          label="Seat colour*"
          placeholder="Select"
          name="seat_color"
          data-test-id="seats_color-input"
        />
      </div>
    </div>
    <div class="col-6">
      <div class="mb-4">
        <MuiSelect
          v-model="payload.transmission"
          v-validate="{
            isRequired: true,
          }"
          :options="values(TRANSMISSION_TYPES)"
          class="w-100 text-capitalize"
          label="Transmission*"
          placeholder="Select"
          name="transmission"
          data-test-id="transmission-select"
        />
      </div>
    </div>

    <div class="col-6">
      <div class="mb-4">
        <MuiInputText
          v-model="payload.engine"
          class="w-100"
          placeholder="Enter (e.g. TDI)"
          label="Engine"
          name="engine"
        />
      </div>
    </div>

    <div class="col-6">
      <div class="mb-4">
        <MuiInputText
          v-model.number="payload.horse_power"
          v-validate="{
            isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
          }"
          label="Horse power (hp)"
          name="horse_power"
          placeholder="Enter number value"
          data-test-id="horse_power-input"
        />
      </div>
    </div>

    <div class="col-6">
      <MuiInputText
        v-model.number="payload.co2_emission"
        v-validate="{
          isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
        }"
        label="CO2 emission (g/km)"
        name="co2"
        placeholder="Enter number value (e.g. 120.4)"
        data-test-id="co2-input"
        @blur="value => payload.co2_emission = toNumber(value)"
      />
    </div>
    <div class="col-6">
      <MuiInputText
        v-model.number="payload.fuel_consumption"
        v-validate="{
          isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
        }"
        label="Fuel consumption (km/l)"
        name="fuel"
        placeholder="Enter number value (e.g. 6.3)"
        data-test-id="fuel-input"
        @blur="value => payload.fuel_consumption = toNumber(value)"
      />
    </div>
  </div>
</template>

