<script>
import get from 'lodash/get';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapGetters } from 'vuex';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { eventsContentCells } from '../config/logsContentCells';

export default {
  name: 'LogsEventsView',
  components: { MuiAlgoliaList },
  data() {
    return {
      vehicleUuid: get(this, '$route.params.vehicle_uuid'),
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'operatorTimezone',
    ]),
    transceiverEventsFilters() {
      return `vehicle_id:${this.vehicleUuid}`; // Mismatch due to alogia table storing uuid as id
    },
    transceiverEventsFacets() {
      return [
        {
          type: 'refinementList',
          props: {
            title: 'Name',
            attributeName: 'meta.name',
            transformValue: sentenceCase,
          },
        },
      ];
    },
  },
  created() {
    this.TRANSCEIVERS_INDEX = ALGOLIA_INDEXES.transceiverEvents;
    this.contentCells = eventsContentCells(this.operatorTimezone);
  },
};
</script>

<template>
  <div class="LogsEventsView">
    <h1 class="mb-2">
      Vehicle events
    </h1>
    <MuiAlgoliaList
      :export-columns="contentCells"
      :facets="transceiverEventsFacets"
      :filters="`vehicle_id: ${vehicleUuid}`"
      :index="TRANSCEIVERS_INDEX"
      :table-config="contentCells"
      is-export-enabled
      is-sidebar-collapsed
    />
  </div>
</template>
