var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LogsEventsView" },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Connectivity ")]),
      _c("FilterTableComponent", {
        attrs: {
          schema: _vm.connectivitySchema,
          "export-schema": _vm.connectivityExportSchema,
          data: _vm.connectivityLogData,
          "is-loading": _vm.connectivityLogLoading,
          exportable: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }