<script>
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import snakeCase from 'lodash/snakeCase';
import { sentenceCase } from '@emobg/web-utils';

export default {
  name: 'EditEquipmentComponent',
  props: {
    equipment: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      originalPayload: null,
      payload: {
        equipment: this.equipment,
      },
    };
  },
  computed: {
    hasSameValues() {
      return isEqual(this.payload, this.originalPayload);
    },
  },
  watch: {
    hasSameValues(value) {
      this.$emit('update:payload', value);
    },
    payload: {
      handler() {
        const checkedEquipment = filter(this.payload.equipment, { checked: true });
        this.$emit('update', {
          equipment_uuids: map(checkedEquipment, 'equipment_uuid'),
        });
      },
      deep: true,
    },
  },
  created() {
    this.originalPayload = cloneDeep(this.payload);
  },
  methods: {
    sentenceCase,
    snakeCase,
  },
};
</script>

<template>
  <div class="EditEquipmentComponent row">
    <div
      v-for="item in payload.equipment"
      :key="item.equipment_uuid"
      class="col-6 col-md-4 d-flex align-items-center"
    >
      <ui-icon
        :icon="snakeCase(item.internal_name)"
        :size="ICONS_SIZES.small"
        :color="GRAYSCALE.ink"
      />
      <ui-toggle
        :value="item.checked"
        :text="sentenceCase(item.name)"
        data-test-id="active-toggle"
        name="deviceStatus"
        class="pl-2 py-2 d-block"
        @changevalue="({ detail }) => item.checked = detail"
      />
    </div>
  </div>
</template>
