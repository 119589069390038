var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DamagesComponent row" },
    _vm._l(_vm.transformDamages, function (side) {
      return _c("div", { key: side.name, staticClass: "col-md-3" }, [
        _c(
          "div",
          { staticClass: "position-relative" },
          [
            _c("img", {
              staticClass: "w-100",
              attrs: { src: side.url, alt: "side" },
            }),
            _vm._l(side.damages, function (damage, index) {
              return _c(
                "div",
                { key: index },
                _vm._l(damage, function (marker) {
                  return _c("div", {
                    key: marker.id,
                    staticClass:
                      "DamagesComponent__marker circle bg-color-danger position-absolute",
                    style: {
                      top: `${marker.coordinate_y}%`,
                      left: `${marker.coordinate_x}%`,
                    },
                  })
                }),
                0
              )
            }),
          ],
          2
        ),
        _c("div", { staticClass: "emobg-font-weight-semibold text-center" }, [
          _vm._v(" " + _vm._s(side.label) + " "),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }