<script>
import get from 'lodash/get';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  DATE_FORMAT,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { mapState } from 'vuex';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { contentCells, locationRequestsFacets } from './config/contentCells';

export default {
  name: 'LocationRequestsView',
  components: {
    MuiAlgoliaList,
  },
  data() {
    return {
      vehicleUuid: get(this, '$route.params.vehicle_uuid'),
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorTimezone: state => get(state, 'operators.active.timezone'),
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      licensePlate: state => get(state, 'general.data.license_plate'),
    }),
  },
  created() {
    this.VEHICLES_LOCATION_REQUESTS_INDEX = ALGOLIA_INDEXES.vehicleLocationRequests;
    this.contentCells = contentCells(this.activeOperatorTimezone);
    this.facets = locationRequestsFacets(this.activeOperatorTimezone);
    this.exportColumns = [
      {
        attributeName: 'date',
        title: 'Date',
        transformValue: value => (value ? formatUtc(value, DATE_FORMAT.defaultExtended, this.activeOperatorTimezone) : this.FALLBACK_MESSAGE.dash),
      },
      {
        attributeName: 'reason',
        title: 'Reason',
        transformValue: sentenceCase,
      },
      {
        title: 'License plate',
        transformResult: () => this.licensePlate,
      },
    ];
  },
};
</script>

<template>
  <div class="LocationRequestsView">
    <div class="d-flex align-items-center mb-2">
      <h1>
        Vehicle location requests
      </h1>
      <ui-tooltip
        tooltip="Requests to see the vehicle location on the map during the booking"
        class="ml-1"
      >
        <ui-icon
          :icon="ICONS.infoFull"
          :size="ICONS_SIZES.medium"
          class="emobg-color-ink-light emobg-color-ink-hover"
        />
      </ui-tooltip>
    </div>
    <MuiAlgoliaList
      :export-columns="exportColumns"
      :filters="`vehicle_uuid:${vehicleUuid}`"
      :table-config="contentCells"
      :facets="facets"
      :index="VEHICLES_LOCATION_REQUESTS_INDEX"
      is-export-enabled
    />
  </div>
</template>
